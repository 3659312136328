const config = {
  site: {
    title: 'Chordinatr!',
    url: process.env.SITEURL,
  },
  ga: {
    id: process.env.REACT_APP_GA_KEY,
  },
};

export default config;
