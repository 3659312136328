import React from 'react';
import DegreesRow from 'components/degrees/row';
import DegreesRowHeader from 'components/degrees/header';
import ScaleDegree from 'utilities/degree';
import propTypesHelper from 'utilities/prop-types-helper';
import classNames from 'classnames';
import FontAwesomeIcon from 'utilities/font-awesome';

const DegreesContainer = props => {
  const {
    degrees,
    displayIntervalsAs,
    highlight,
    playSounds,
    scaleDegrees,
    setDegrees,
    setHighlight,
    setPlaySounds,
    getActiveChord,
    setActiveNote,
    activeNote,
    activeNoteSticky,
    tonic,
    toggleDegreesTable,
    degreesTableIn,
    playRootAfterArpeggio,
  } = props;

  const rows = degrees.map((degree, ix) => {
    const key = ix;
    const scaleDegree = new ScaleDegree(tonic, degree);

    return (
      <DegreesRow
        degrees={degrees}
        displayIntervalsAs={displayIntervalsAs}
        highlight={highlight}
        key={key}
        position={ix}
        setDegrees={setDegrees}
        setHighlight={setHighlight}
        setPlaySounds={setPlaySounds}
        startingDegree={scaleDegree}
        getActiveChord={getActiveChord}
        tonic={tonic}
      />
    );
  });

  const tableClass = classNames({
    table: true,
    'table-hover': true,
    'table-striped': true,
    in: degreesTableIn,
  });

  const faIcon = degreesTableIn ? 'caret-up' : 'caret-down';

  return (
    <div className="degrees">
      <table className={tableClass}>
        <DegreesRowHeader
          key="header"
          scaleDegrees={scaleDegrees}
          setPlaySounds={setPlaySounds}
          tonic={tonic}
          degrees={degrees}
          playSounds={playSounds}
          tableToggle={toggleDegreesTable}
          tableIn={degreesTableIn}
          setActiveNote={setActiveNote}
          activeNote={activeNote}
          activeNoteSticky={activeNoteSticky}
          playRootAfterArpeggio={playRootAfterArpeggio}
        />
        <tbody>{rows}</tbody>
      </table>
      <button
        type="button"
        className="btn btn-info btn-block btn-expand-degrees"
        onClick={() => toggleDegreesTable()}
      >
        <FontAwesomeIcon icon={faIcon} />
      </button>
    </div>
  );
};

DegreesContainer.defaultProps = {
  highlight: '',
};

DegreesContainer.propTypes = propTypesHelper({
  scaleDegrees: true,
  displayIntervalsAs: true,
  degrees: true,
  tonic: true,
  setPlaySounds: true,
  setDegrees: true,
  playSounds: true,
  highlight: false,
});

export default DegreesContainer;
