import React from 'react';
import scales from 'config/scales';
import propTypesHelper from 'utilities/prop-types-helper';
import reactGA from 'react-ga';

const SettingsQuickSelect = props => {
  const { setDegrees, degrees } = props;

  const handleChange = event => {
    const { value } = event.target;
    if (value) {
      const split = value.split(',');
      reactGA.event({
        category: 'Settings',
        action: 'Quick scale',
        label: split.join(', '),
      });
      setDegrees(value.split(','));
    }
  };

  const options = scales.map(scale => {
    const { degrees: scaleDegrees, label } = scale;
    const degreesString = scaleDegrees.join(',');
    return (
      <option key={degreesString} value={degreesString}>
        {label}
      </option>
    );
  });

  options.unshift(
    <option key={-1} value="">
      -
    </option>
  );

  return (
    <fieldset>
      <div className="form-group">
        <label htmlFor="quick-scale">Quick scales:</label>
        <select
          value={degrees.join(',')}
          className="form-control"
          id="quick-scale"
          type="text"
          onChange={handleChange}
        >
          {options}
        </select>
      </div>
    </fieldset>
  );
};

SettingsQuickSelect.propTypes = propTypesHelper({
  setDegrees: true,
  degrees: true,
});

export default SettingsQuickSelect;
