import React from 'react';
import reactGA from 'react-ga';
import ScaleDegreeSingle from 'components/degrees/single';
import FontAwesomeIcon from 'utilities/font-awesome';
import propTypesHelper from 'utilities/prop-types-helper';
import classNames from 'classnames';

const DegreesRowHeader = props => {
  const {
    tonic,
    scaleDegrees,
    playSounds,
    tableToggle,
    tableIn,
    activeNoteSticky,
    activeNote,
    playRootAfterArpeggio,
  } = props;

  const handleClick = scaleDegree => {
    const { setActiveNote } = props;
    const note = scaleDegree.getNote();
    if (!activeNoteSticky || activeNote !== note) {
      setActiveNote(note, true);
    } else {
      setActiveNote(null);
    }
  };
  const handleMouseOver = scaleDegree => {
    const { setActiveNote } = props;
    const note = scaleDegree.getNote();
    if (!activeNoteSticky) {
      setActiveNote(note);
    }
  };
  const handleMouseLeave = () => {
    const { setActiveNote } = props;
    if (!activeNoteSticky) {
      setActiveNote(null);
    }
  };

  const scaleDegreeElements = scaleDegrees.map((scaleDegree, ix) => {
    const key = `${tonic}-${scaleDegree.getDegree()}-${ix}`;
    const myHandleMouseOver = () => handleMouseOver(scaleDegree);
    return (
      <th key={key} scope="col">
        <ScaleDegreeSingle
          scaleDegree={scaleDegree}
          label="combo"
          isHeaderDegree
          playSounds={playSounds}
          onClick={() => handleClick(scaleDegree)}
          onFocus={myHandleMouseOver}
          onMouseOver={myHandleMouseOver}
          onMouseLeave={handleMouseLeave}
          onBlur={handleMouseLeave}
          activeNote={activeNote}
          activeNoteSticky={activeNoteSticky}
          playRootAfterArpeggio={playRootAfterArpeggio}
        />
      </th>
    );
  });

  const playScale = () => {
    const { setPlaySounds } = props;
    let notes = scaleDegrees.map(scaleDegree => scaleDegree.getLabel());
    if (playRootAfterArpeggio) {
      notes = notes.concat(notes[0]);
    }
    reactGA.event({
      category: 'Sound',
      action: 'Scale',
      label: notes.join(', '),
    });

    setPlaySounds(notes, true);
  };

  const editBtnClass = classNames({
    btn: true,
    'btn-info': true,
    'btn-sm': true,
    active: tableIn,
  });

  return (
    <thead>
      <tr>
        <th key="padding" scope="col" className="actions">
          <button
            type="button"
            onClick={playScale}
            className="btn btn-info btn-sm"
          >
            <span className="d-none d-sm-inline mr-2">Play scale</span>
            <FontAwesomeIcon icon="volume-up" />
          </button>
          <button
            type="button"
            onClick={() => tableToggle()}
            id="edit-degrees-trigger"
            className={editBtnClass}
          >
            <span className="pr-1 d-none d-sm-inline mr-2">Edit scale</span>
            <FontAwesomeIcon icon="edit" />
          </button>
        </th>
        {scaleDegreeElements}
      </tr>
    </thead>
  );
};

DegreesRowHeader.propTypes = propTypesHelper({
  scaleDegrees: true,
  tonic: true,
  setPlaySounds: true,
  playSounds: true,
});

export default DegreesRowHeader;
