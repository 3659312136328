export default [
  {
    label: 'Lydian',
    degrees: ['I', 'II', 'III', '#IV', 'V', 'VI', 'VII'],
  },
  {
    label: 'Ionian (Major)',
    degrees: ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII'],
  },
  {
    label: 'Mixolydian',
    degrees: ['I', 'II', 'III', 'IV', 'V', 'VI', 'bVII'],
  },
  {
    label: 'Dorian',
    degrees: ['I', 'II', 'bIII', 'IV', 'V', 'VI', 'bVII'],
  },
  {
    label: 'Aeolian (Minor)',
    degrees: ['I', 'II', 'bIII', 'IV', 'V', 'bVI', 'bVII'],
  },
  {
    label: 'Phyrgian',
    degrees: ['I', 'bII', 'bIII', 'IV', 'V', 'bVI', 'bVII'],
  },
  {
    label: 'Locrian',
    degrees: ['I', 'bII', 'bIII', 'IV', 'bV', 'bVI', 'bVII'],
  },
  {
    label: 'Major pentatonic',
    degrees: ['I', 'II', 'III', 'V', 'VI'],
  },
  {
    label: 'Minor pentatonic',
    degrees: ['I', 'bIII', 'IV', 'V', 'bVII'],
  },
  {
    label: 'Blues',
    degrees: ['I', 'bIII', 'bIV', 'IV', 'V', 'bVII'],
  },
  {
    label: 'Harmonic minor',
    degrees: ['I', 'II', 'bIII', 'IV', 'V', 'bVI', 'VII'],
  },
  {
    label: 'Melodic minor',
    degrees: ['I', 'II', 'bIII', 'IV', 'V', 'VI', 'VII'],
  },
  {
    label: 'Double harmonic major',
    degrees: ['I', 'bII', 'III', 'IV', 'V', 'bVI', 'VII'],
  },
  {
    label: 'Diminished',
    degrees: ['I', 'II', 'bIII', 'IV', 'bV', '#V', 'VI', 'VII'],
  },
  {
    label: 'Major 6th Diminished',
    degrees: ['I', 'II', 'III', 'IV', 'V', 'bVI', 'VI', 'VII'],
  },
  {
    label: 'Minor 6th Diminished',
    degrees: ['I', 'II', 'bIII', 'IV', 'V', 'bVI', 'VI', 'VII'],
  },
  {
    label: 'Seventh Diminished',
    degrees: ['I', 'II', 'III', 'IV', 'V', 'bVI', 'bVII', 'VII'],
  },
  {
    label: 'Seventh Flat 5 Diminished',
    degrees: ['I', 'II', 'III', 'IV', 'bV', 'bVI', 'bVII', 'VII'],
  },
  {
    label: 'Chromatic (ALL THE CHORDS!)',
    degrees: [
      'I',
      'bII',
      'II',
      'bIII',
      'III',
      'IV',
      'bV',
      'V',
      'bVI',
      'VI',
      'bVII',
      'VII',
    ],
  },
];
