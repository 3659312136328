import React, { Component } from 'react';
import reactGA from 'react-ga';
import ReactJoyride, { EVENTS, STATUS, ACTIONS } from 'react-joyride';
import steps, { getStepIndexBySlug } from 'config/tour-steps';
import scales from 'config/scales';
import chords from 'config/chords';
import Chord from 'utilities/chord';

class Tour extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stepIndex: 0,
    };
    this.stepCallback = this.stepCallback.bind(this);
    this.setStepIndex = this.setStepIndex.bind(this);
  }

  componentDidUpdate() {
    const { stepIndex } = this.state;
    const { isStickyChord, degreesTableIn, degrees } = this.props;

    if (
      (stepIndex === getStepIndexBySlug('CHORDS-LEFT-CLICK') &&
        isStickyChord('left')) ||
      (stepIndex === getStepIndexBySlug('CHORDS-RIGHT-CLICK') &&
        isStickyChord('left') &&
        isStickyChord('right')) ||
      (stepIndex === getStepIndexBySlug('SCALE-EDIT') && degreesTableIn) ||
      (stepIndex === getStepIndexBySlug('SCALE-FLATTEN') &&
        degrees.join(',') === 'I,II,bIII,IV,V,VI,VII')
    ) {
      this.nextStep();
    }
  }

  setStepIndex(stepIndex) {
    const { stepIndex: current } = this.state;
    if (stepIndex !== current) {
      this.setState({ stepIndex });
    }
  }

  resetApp() {
    const {
      setTonic,
      clearActiveChords,
      setDegrees,
      toggleDegreesTable,
      setActiveNote,
    } = this.props;
    clearActiveChords();
    setDegrees(scales.find(scale => scale.label === 'Ionian (Major)').degrees);
    setTonic('C');
    toggleDegreesTable(false);
    setActiveNote(null);
    document.querySelectorAll('.degrees-cell-inner').forEach(el => {
      el.style.overflow = null;
    });
  }

  nextStep() {
    const { stepIndex: current } = this.state;
    this.setStepIndex(current + 1);
  }

  prevStep() {
    const { stepIndex: current } = this.state;
    this.setStepIndex(current - 1);
  }

  stepCallback({ action, index, type, status }) {
    const setMiddleChord = () => {
      const { setActiveChords, scaleDegrees } = this.props;
      const chord = new Chord(
        chords.getSets().Triads.find(crd => crd.name === 'Major seven')
      );
      chord.setNotes('C', scaleDegrees);
      setActiveChords('middle', chord, true);
    };

    const { setRunTour } = this.props;

    if ([ACTIONS.START, ACTIONS.CLOSE].includes(action)) {
      reactGA.event({
        category: 'Tour',
        action,
      });
    } else if (
      [ACTIONS.PREV, ACTIONS.NEXT].includes(action) &&
      type === EVENTS.STEP_BEFORE
    ) {
      reactGA.event({
        category: 'Tour',
        action,
        label: steps[index].slug,
        value: index,
      });
    } else if (
      [ACTIONS.NEXT].includes(action) &&
      type === EVENTS.STEP_AFTER &&
      index === steps.length - 2
    ) {
      reactGA.event({
        category: 'Tour',
        action,
        label: steps[steps.length - 1].slug,
        value: steps.length - 1,
      });
    } else if (
      [ACTIONS.PREV].includes(action) &&
      type === EVENTS.STEP_AFTER &&
      index === 1
    ) {
      reactGA.event({
        category: 'Tour',
        action,
        label: steps[0].slug,
        value: 0,
      });
    } else if ([STATUS.FINISHED].includes(status)) {
      reactGA.event({
        category: 'Tour',
        action,
      });
    }

    if (index === 0) {
      this.resetApp();
    }

    if (
      [STATUS.FINISHED, STATUS.SKIPPED].includes(status) ||
      action === ACTIONS.CLOSE
    ) {
      this.resetApp();
      setRunTour(false);
      this.setStepIndex(0);
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      let stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);

      if (index === 6) {
        setMiddleChord();
        stepIndex = 8;
      }

      this.setStepIndex(stepIndex);
    }
  }

  render() {
    const { runTour } = this.props;
    const { stepIndex } = this.state;
    return (
      <ReactJoyride
        run={runTour}
        steps={steps}
        callback={this.stepCallback}
        continuous
        disableOverlayClose
        stepIndex={stepIndex}
        styles={{
          overlay: {
            mixBlendMode: 'multiply',
          },
          spotlight: {
            backgroundColor: '#fff',
          },
        }}
      />
    );
  }
}

export default Tour;
