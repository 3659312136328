import React from 'react';
import propTypesHelper from 'utilities/prop-types-helper';

import {
  addIntervalToNote,
  subtractIntervalFromNote,
} from 'utilities/notation';

import classNames from 'classnames';

const SettingsTonicInput = React.forwardRef((props, ref) => {
  const { tonic, className } = props;

  const handleChange = event => {
    const { trackingCategory: category } = props;
    const { value } = event.target;
    const { setTonic } = props;
    if (value) {
      setTonic(value, category);
    }
  };

  const handleBlur = event => {
    const { target } = event;
    if (target.value !== tonic) {
      target.value = tonic;
    }
  };

  const handleKeyUp = event => {
    const { trackingCategory: category } = props;
    const { setTonic } = props;
    const { target } = event;
    let newTonic;
    switch (event.key) {
      case 'ArrowUp':
        newTonic = addIntervalToNote(tonic, 1);
        break;
      case 'ArrowDown':
        newTonic = subtractIntervalFromNote(tonic, 1);
        break;
      case 'Backspace':
      case 'Delete':
        target.value = tonic.substr(0, -1);
        break;
      default:
        break;
    }
    if (newTonic) {
      event.preventDefault();
      setTonic(newTonic, category);
    }
  };

  const cls = {
    'settings-tonic': true,
  };
  if (className) {
    cls[className] = true;
  }

  return (
    <input
      ref={ref}
      autoComplete="off"
      type="text"
      value={tonic}
      onChange={handleChange}
      onKeyUp={handleKeyUp}
      onBlur={handleBlur}
      className={classNames(cls)}
    />
  );
});

SettingsTonicInput.defaultProps = {
  className: '',
};

SettingsTonicInput.propTypes = propTypesHelper({
  tonic: true,
  setTonic: true,
  className: false,
});

export default SettingsTonicInput;
