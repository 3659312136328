import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import requiredIf from 'react-required-if';
import { noop } from 'utilities/general';
import { displayIntervalAs } from 'utilities/notation';
import Tooltip from 'rc-tooltip';
import FontAwesomeIcon from 'utilities/font-awesome';
import propTypesHelper from 'utilities/prop-types-helper';
import defaultPropTypes from 'config/default-prop-types';

class DegreesSingle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasChanged: false,
      scaleDegree: null,
      startingDegree: null,
    };
    this.handleAnimationEnd = this.handleAnimationEnd.bind(this);
    this.handlePlayAscending = this.handlePlayAscending.bind(this);
    this.handlePlayDescending = this.handlePlayDescending.bind(this);
    this.handlePlayTogether = this.handlePlayTogether.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let hasChanged = false;

    if (prevState.scaleDegree) {
      const nextScaleDegree = nextProps.scaleDegree;
      const prevScaleDegree = prevState.scaleDegree;
      hasChanged = nextScaleDegree.getDegree() !== prevScaleDegree.getDegree();
      if (nextProps.startingDegree) {
        const newStartingDegree = nextProps.startingDegree.getDegree();
        const oldStartingDegree = prevState.startingDegree.getDegree();
        const nextInterval = nextScaleDegree.getIntervalFrom(newStartingDegree);
        const prevInterval = prevScaleDegree.getIntervalFrom(oldStartingDegree);
        hasChanged = nextInterval !== prevInterval;
      }
    }
    return {
      hasChanged,
      scaleDegree: nextProps.scaleDegree,
      startingDegree: nextProps.startingDegree,
    };
  }

  getLabel() {
    const { label, scaleDegree, displayIntervalsAs } = this.props;

    const degree = scaleDegree.getDegree();

    let formattedLabel = degree;
    switch (label) {
      case 'combo':
        formattedLabel = (
          <span>
            <span className="degree d-none d-sm-inline">{degree}</span>

            <span className="ml-1 note">
              <span className="d-none d-sm-inline">(</span>
              <span>{scaleDegree.getLabel()}</span>
              <span className="d-none d-sm-inline">)</span>
            </span>
          </span>
        );
        break;
      case 'degree':
        formattedLabel = degree;
        break;
      case 'note':
        formattedLabel = scaleDegree.getLabel();
        break;
      case 'interval':
        formattedLabel = displayIntervalAs(displayIntervalsAs, this.interval);
        break;
      default:
        break;
    }
    return formattedLabel;
  }

  handleAnimationEnd() {
    this.setState({ hasChanged: false });
  }

  handlePlayAscending() {
    const { setPlaySounds, startingDegree, scaleDegree } = this.props;
    setPlaySounds([startingDegree.getLabel(), scaleDegree.getLabel()], true);
  }

  handlePlayDescending() {
    const { setPlaySounds, startingDegree, scaleDegree } = this.props;
    setPlaySounds(
      [scaleDegree.getLabel(), startingDegree.getLabel()],
      true,
      true
    );
  }

  handlePlayTogether() {
    const { setPlaySounds, startingDegree, scaleDegree } = this.props;
    setPlaySounds(
      [[startingDegree.getLabel(), scaleDegree.getLabel()].join(',')],
      true
    );
  }

  handleClick() {
    const { setActiveNote, activeNote, scaleDegree } = this.sprops;
    const note = scaleDegree.getNote();
    if (activeNote === note) {
      setActiveNote(null);
    } else {
      setActiveNote(note, true);
    }
  }

  render() {
    const {
      isHeaderDegree,
      isStartingDegree,
      playSounds,
      scaleDegree,
      setPlaySounds,
      startingDegree,
      getActiveChord,
      onClick,
      onMouseLeave,
      onMouseOver,
      activeNote,
      activeNoteSticky,
    } = this.props;

    const { hasChanged } = this.state;

    let playing = false;

    if (playSounds) {
      const [playingNotes] = playSounds;
      const note = scaleDegree.getLabel();
      if (isHeaderDegree && playingNotes.length) {
        if (playingNotes[0] === note) {
          playing = true;
        } else if (playingNotes[0].match(',')) {
          const notes = playingNotes[0].split(',');
          if (notes.indexOf(note) !== -1) {
            playing = true;
          }
        }
      }
    }

    const classes = {
      'degree-single': true,
      highlightable: true,
      'scale-degree': true,
      'scale-degree-starting': isStartingDegree,
      animated: playing,
      heartBeat: playing,
      btn: true,
      'btn-default': !activeNoteSticky || activeNote !== scaleDegree.getNote(),
      'btn-success': !!activeNoteSticky && activeNote === scaleDegree.getNote(),
    };

    if (startingDegree) {
      this.interval = scaleDegree.getIntervalFrom(startingDegree.getDegree());
      classes[
        `intersection-${startingDegree.getNaturalDegree()}-${scaleDegree.getNaturalDegree()}`.toLowerCase()
      ] = true;
    }

    const innerClasses = {
      'd-inline-block': true,
      animated: hasChanged,
      heartBeat: hasChanged,
    };

    if (startingDegree) {
      const leftChord = getActiveChord('left');
      const rightChord = getActiveChord('right');
      const rowRootNote = startingDegree.getLabel();
      const interval = this.interval.toLowerCase();
      if (
        leftChord &&
        leftChord.rootNote === rowRootNote &&
        leftChord.hasInterval(this.interval)
      ) {
        classes[`highlight-${interval}-active`] = true;
      }
      if (
        rightChord &&
        rightChord.rootNote === rowRootNote &&
        rightChord.hasInterval(this.interval)
      ) {
        classes[`highlight-${interval}-comparison`] = true;
      }
      classes[`is-${interval}`] = true;
    }

    let label = this.getLabel();

    if (setPlaySounds && this.interval !== 'I') {
      classes['cursor-pointer'] = true;
      const tooltipContent = (
        <div>
          <button
            className="btn btn-sm btn-block btn-info text-left"
            type="button"
            onClick={this.handlePlayAscending}
          >
            <FontAwesomeIcon icon="volume-up" />
            <span className="ml-2">Ascending</span>
          </button>
          <button
            className="btn btn-sm btn-block btn-info text-left"
            type="button"
            onClick={this.handlePlayDescending}
          >
            <FontAwesomeIcon icon="volume-up" />
            <span className="ml-2">Descending</span>
          </button>

          <button
            className="btn btn-sm btn-block btn-info text-left"
            type="button"
            onClick={this.handlePlayTogether}
          >
            <FontAwesomeIcon icon="volume-up" />
            <span className="ml-2">Together</span>
          </button>
        </div>
      );

      label = (
        <Tooltip trigger={['click']} overlay={tooltipContent}>
          <span>{this.getLabel()}</span>
        </Tooltip>
      );
    }

    return (
      <button
        type="button"
        className={`${classNames(classes)}`}
        onMouseOver={onMouseOver}
        onFocus={onMouseOver}
        onMouseLeave={onMouseLeave}
        onBlur={onMouseLeave}
        onClick={onClick}
      >
        <div
          className={classNames(innerClasses)}
          onAnimationEnd={this.handleAnimationEnd}
        >
          {label}
        </div>
      </button>
    );
  }
}

DegreesSingle.defaultProps = {
  displayIntervalsAs: null,
  isStartingDegree: false,
  label: 'degree',
  setHighlight: noop,
  setPlaySounds: null,
  startingDegree: null,
  isHeaderDegree: false,
  playSounds: null,
  onClick: noop,
  onMouseOver: noop,
  onMouseLeave: noop,
};

DegreesSingle.propTypes = propTypesHelper(
  {
    scaleDegree: true,
    setHighlight: false,
    setPlaySounds: false,
    playSounds: false,
    startingDegree: false,
  },
  {
    displayIntervalsAs: requiredIf(
      defaultPropTypes.displayIntervalsAs,
      props => props.label === 'interval'
    ),
    label: PropTypes.oneOf(['combo', 'degree', 'note', 'interval']),
    isHeaderDegree: PropTypes.bool,
    isStartingDegree: PropTypes.bool,
  }
);

export default DegreesSingle;
