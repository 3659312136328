import React from 'react';

import PropTypes from 'prop-types';
import propTypesHelper from 'utilities/prop-types-helper';

import SettingsPlayRootAfterArpeggio from 'components/settings/play-root-after-arpeggio';
import SettingsDisplayIntervalsAs from 'components/settings/display-intervals-as';
import SettingsDegrees from 'components/settings/degrees';
import SettingsTonic from 'components/settings/tonic';
import SettingsQuickSelect from 'components/settings/quick/select';
import { push as Menu } from 'react-burger-menu';

const Settings = props => {
  const {
    degrees,

    isOpen,
    setDegrees,

    setMenuIsOpen,

    setTonic,
    tonic,

    setDisplayIntervalsAs,
    displayIntervalsAs,

    setPlayRootAfterArpeggio,
    playRootAfterArpeggio,
  } = props;

  const handleMenuStateChange = state => {
    setMenuIsOpen(state.isOpen);
    setTimeout(() => {
      if (state.isOpen) {
        document.activeElement.blur();
      }
    });
  };

  return (
    <Menu
      right
      isOpen={isOpen}
      customBurgerIcon={false}
      onStateChange={handleMenuStateChange}
      pageWrapId="chordinatr"
      outerContainerId="page-wrap"
    >
      <form className="settings-wrap">
        <SettingsTonic setTonic={setTonic} tonic={tonic} />
        <SettingsDegrees setDegrees={setDegrees} degrees={degrees} />
        <SettingsQuickSelect setDegrees={setDegrees} degrees={degrees} />
        <SettingsDisplayIntervalsAs
          displayIntervalsAs={displayIntervalsAs}
          setDisplayIntervalsAs={setDisplayIntervalsAs}
        />
        <SettingsPlayRootAfterArpeggio
          setPlayRootAfterArpeggio={setPlayRootAfterArpeggio}
          playRootAfterArpeggio={playRootAfterArpeggio}
        />
      </form>
    </Menu>
  );
};

Settings.propTypes = propTypesHelper(
  {
    degrees: true,
    isDegrees: true,
    setDegrees: true,
    setIsDegrees: true,
    setTonic: true,
    tonic: true,
    displayIntervalsAs: true,
    setDisplayIntervalsAs: true,
  },
  {
    setMenuIsOpen: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
  }
);

export default Settings;
