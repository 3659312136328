import React, { Component } from 'react';

import propTypesHelper from 'utilities/prop-types-helper';
import Sound from 'react-sound';
import soundFiles from 'config/sounds';
import { flatNote2Sharp, noteIsFlat } from 'utilities/notation';
import { noop } from 'utilities/general';

class Sounds extends Component {
  constructor(props) {
    super(props);

    const { playSounds } = props;
    const [notes] = playSounds;
    this.notes = notes;
    this.note = null;
    this.soundFileIndex = 0;

    soundFiles.map(soundFile => {
      const audio = document.createElement('audio');
      audio.src = soundFile.file;
      audio.preload = 'auto';
      return audio;
    });

    this.handleSongFinishedPlaying = this.handleSongFinishedPlaying.bind(this);
  }

  componentDidMount() {
    window.soundManager.setup({
      debugMode: false,
      ignoreMobileRestrictions: true,
    });
  }

  shouldComponentUpdate(nextProps) {
    const { playSounds: currentPlaySounds } = this.props;
    const { playSounds: newPlaySounds } = nextProps;

    if (!currentPlaySounds || !newPlaySounds) {
      return true;
    }

    return currentPlaySounds !== newPlaySounds;
  }

  handleSongFinishedPlaying() {
    const { setPlaySounds } = this.props;
    setPlaySounds(this.notes, false, this.descending);
  }

  soundFileFromNote(note = null) {
    const find = note || this.note;
    const normalised = noteIsFlat(find) ? flatNote2Sharp(find) : find;

    let sliced = soundFiles.slice();

    if (this.descending) {
      sliced = sliced.reverse();
    }

    sliced = sliced.slice(this.soundFileIndex);

    const soundFileIndex = sliced.findIndex(file => {
      return file.note === normalised;
    });

    if (soundFileIndex !== -1) {
      this.soundFileIndex += soundFileIndex;
      return sliced[soundFileIndex];
    }
    return null;
  }

  finishAndReset() {
    this.soundFileIndex = 0;
    return '';
  }

  render() {
    const { playSounds } = this.props;

    const [notes, fresh, descending] = playSounds;

    this.descending = descending;

    if (fresh) {
      this.soundFileIndex = 0;
    }

    if (!notes || !notes.length) {
      return this.finishAndReset();
    }

    this.notes = notes.slice();

    const soundElement = (soundFile, stop = null) => {
      let onPlaying = noop;
      if (stop) {
        onPlaying = playing => {
          if (playing.position > stop) {
            this.handleSongFinishedPlaying();
          }
        };
      }

      return soundFile ? (
        <Sound
          key={soundFile.file}
          url={soundFile.file}
          onPlaying={onPlaying}
          playStatus={Sound.status.PLAYING}
          playFromPosition={0}
          onFinishedPlaying={this.handleSongFinishedPlaying}
        />
      ) : (
        ''
      );
    };

    this.note = this.notes.shift();

    if (!this.note) {
      return this.finishAndReset();
    }

    let soundFile = this.soundFileFromNote();
    if (soundFile) {
      return soundElement(soundFile, 500);
    }

    if (this.note.match(',')) {
      this.soundFileIndex = 0;
      return this.note.split(',').map(chordNote => {
        soundFile = this.soundFileFromNote(chordNote);
        return soundElement(soundFile);
      });
    }
    return this.finishAndReset();
  }
}

Sounds.defaultProps = {
  playSounds: [[], false, false],
};

Sounds.propTypes = propTypesHelper({
  setPlaySounds: true,
  playSounds: true,
});

export default Sounds;
