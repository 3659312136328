import React from 'react';
import PropTypes from 'prop-types';
import propTypesHelper from 'utilities/prop-types-helper';

const SettingsRadio = props => {
  const handleChange = event => {
    const { setter } = props;
    let { value } = event.target;

    if (value === 'true') {
      value = true;
    } else if (value === 'false') {
      value = false;
    }

    setter(value);
  };

  const { value, checked, label, id } = props;

  return (
    <div className="form-check">
      <input
        type="radio"
        id={id}
        checked={checked}
        onChange={handleChange}
        value={value}
        className="form-check-input"
      />
      <label htmlFor={id} className="form-check-label">
        {label}
      </label>
    </div>
  );
};

SettingsRadio.propTypes = propTypesHelper(
  {
    checked: true,
    label: true,
  },
  {
    setter: PropTypes.func.isRequired,
    value: PropTypes.any.isRequired,
    id: PropTypes.string.isRequired,
  }
);

export default SettingsRadio;
