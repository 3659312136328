import React from 'react';
import propTypesHelper from 'utilities/prop-types-helper';

const ChordsHeader = props => {
  const { chords } = props;
  const cols = chords.map(chord => {
    return (
      <th key={chord.name} scope="col">
        {chord.header || chord.symbol}
      </th>
    );
  });
  return (
    <thead className="">
      <tr>
        <th />
        {cols}
      </tr>
    </thead>
  );
};

ChordsHeader.propTypes = propTypesHelper({
  displayIntervalsAs: true,
  chords: true,
});

export default ChordsHeader;
