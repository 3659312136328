import React, { Component } from 'react';
import PropTypes from 'prop-types';
import propTypesHelper from 'utilities/prop-types-helper';
import SettingsTonicInput from 'components/settings/tonic/input';

import FontAwesomeIcon from 'utilities/font-awesome';
import SettingsQuickDropdown from 'components/settings/quick/dropdown';

class Header extends Component {
  constructor(props) {
    super(props);
    this.tonicInput = React.createRef();
    this.handleTonicButtonClick = this.handleTonicButtonClick.bind(this);
    this.handleMenuToggleClick = this.handleMenuToggleClick.bind(this);
    this.handleRunTourClick = this.handleRunTourClick.bind(this);
  }

  handleTonicButtonClick() {
    this.tonicInput.current.focus();
  }

  handleRunTourClick() {
    const { setRunTour } = this.props;
    setRunTour(true);
  }

  handleMenuToggleClick() {
    const { menuIsOpen, setMenuIsOpen } = this.props;
    setMenuIsOpen(!menuIsOpen);
  }

  render() {
    const { setTonic, tonic, setDegrees, degrees } = this.props;

    return (
      <header className="chordinatr-header row">
        <div className="col-xs-12 col-sm-3 col-md-4 app-title">
          <h1 className="d-none d-sm-block">
            {process.env.REACT_APP_SITE_NAME}
          </h1>
          <button
            type="button"
            className="btn btn-primary settings-toggle"
            onClick={this.handleMenuToggleClick}
          >
            <FontAwesomeIcon fixedWidth icon="cog" />
          </button>
          <button
            title="Help"
            type="button"
            className="btn btn-info tour-toggle"
            onClick={this.handleRunTourClick}
          >
            <FontAwesomeIcon fixedWidth icon="question" />
          </button>
        </div>
        <div className="col-xs-12 col-sm">
          <div className="row tools">
            <SettingsQuickDropdown setDegrees={setDegrees} degrees={degrees} />

            <button
              type="button"
              onClick={this.handleTonicButtonClick}
              className="btn btn-secondary dropdown-toggle"
            >
              <span className="mr-1">Tonic:</span>
              <SettingsTonicInput
                setTonic={setTonic}
                tonic={tonic}
                ref={this.tonicInput}
                trackingCategory="Header"
              />
            </button>
          </div>
        </div>
      </header>
    );
  }
}

Header.propTypes = propTypesHelper(
  {
    setMenuIsOpen: true,
    setTonic: true,
    tonic: true,
    setDegrees: true,
    degrees: true,
  },
  {
    menuIsOpen: PropTypes.bool.isRequired,
  }
);

export default Header;
