class Interactions {
  constructor(element = window) {
    this.USER_CAN_HOVER = false;
    this.USER_CAN_TOUCH = false;
    this.element = element;

    this.listenersAdded = false;
  }

  addListeners() {
    const self = this;
    if (!self.listenersAdded) {
      self.listenersAdded = true;
      self.element.addEventListener(
        'mouseover',
        function firstMouseOver() {
          self.USER_CAN_HOVER = true;
          self.element.removeEventListener('mouseover', firstMouseOver, false);
        },
        false
      );

      self.element.addEventListener(
        'touchstart',
        function firstTouch() {
          self.USER_CAN_TOUCH = true;
          self.element.removeEventListener('touchstart', firstTouch, false);
        },
        false
      );
    }
  }
}

export { Interactions };

export default new Interactions();
