import sound3C from '../../sounds/3-C.mp3';
import sound3Db from '../../sounds/3-Db.mp3';
import sound3D from '../../sounds/3-D.mp3';
import sound3Eb from '../../sounds/3-Eb.mp3';
import sound3E from '../../sounds/3-E.mp3';
import sound3F from '../../sounds/3-F.mp3';
import sound3Gb from '../../sounds/3-Gb.mp3';
import sound3G from '../../sounds/3-G.mp3';
import sound3Ab from '../../sounds/3-Ab.mp3';
import sound3A from '../../sounds/3-A.mp3';
import sound3Bb from '../../sounds/3-Bb.mp3';
import sound3B from '../../sounds/3-B.mp3';
import sound4C from '../../sounds/4-C.mp3';
import sound4Db from '../../sounds/4-Db.mp3';
import sound4D from '../../sounds/4-D.mp3';
import sound4Eb from '../../sounds/4-Eb.mp3';
import sound4E from '../../sounds/4-E.mp3';
import sound4F from '../../sounds/4-F.mp3';
import sound4Gb from '../../sounds/4-Gb.mp3';
import sound4G from '../../sounds/4-G.mp3';
import sound4Ab from '../../sounds/4-Ab.mp3';
import sound4A from '../../sounds/4-A.mp3';
import sound4Bb from '../../sounds/4-Bb.mp3';
import sound4B from '../../sounds/4-B.mp3';
import sound5C from '../../sounds/5-C.mp3';
import sound5Db from '../../sounds/5-Db.mp3';
import sound5D from '../../sounds/5-D.mp3';
import sound5Eb from '../../sounds/5-Eb.mp3';
import sound5E from '../../sounds/5-E.mp3';
import sound5F from '../../sounds/5-F.mp3';
import sound5Gb from '../../sounds/5-Gb.mp3';
import sound5G from '../../sounds/5-G.mp3';
import sound5Ab from '../../sounds/5-Ab.mp3';
import sound5A from '../../sounds/5-A.mp3';
import sound5Bb from '../../sounds/5-Bb.mp3';
import sound5B from '../../sounds/5-B.mp3';

const soundFiles = [
  {
    note: 'C',
    file: sound3C,
  },
  {
    note: 'C#',
    file: sound3Db,
  },
  {
    note: 'D',
    file: sound3D,
  },
  {
    note: 'D#',
    file: sound3Eb,
  },
  {
    note: 'E',
    file: sound3E,
  },
  {
    note: 'F',
    file: sound3F,
  },
  {
    note: 'F#',
    file: sound3Gb,
  },
  {
    note: 'G',
    file: sound3G,
  },
  {
    note: 'G#',
    file: sound3Ab,
  },
  {
    note: 'A',
    file: sound3A,
  },
  {
    note: 'A#',
    file: sound3Bb,
  },
  {
    note: 'B',
    file: sound3B,
  },
  {
    note: 'C',
    file: sound4C,
  },
  {
    note: 'C#',
    file: sound4Db,
  },
  {
    note: 'D',
    file: sound4D,
  },
  {
    note: 'D#',
    file: sound4Eb,
  },
  {
    note: 'E',
    file: sound4E,
  },
  {
    note: 'F',
    file: sound4F,
  },
  {
    note: 'F#',
    file: sound4Gb,
  },
  {
    note: 'G',
    file: sound4G,
  },
  {
    note: 'G#',
    file: sound4Ab,
  },
  {
    note: 'A',
    file: sound4A,
  },
  {
    note: 'A#',
    file: sound4Bb,
  },
  {
    note: 'B',
    file: sound4B,
  },
  {
    note: 'C',
    file: sound5C,
  },
  {
    note: 'C#',
    file: sound5Db,
  },
  {
    note: 'D',
    file: sound5D,
  },
  {
    note: 'D#',
    file: sound5Eb,
  },
  {
    note: 'E',
    file: sound5E,
  },
  {
    note: 'F',
    file: sound5F,
  },
  {
    note: 'F#',
    file: sound5Gb,
  },
  {
    note: 'G',
    file: sound5G,
  },
  {
    note: 'G#',
    file: sound5Ab,
  },
  {
    note: 'A',
    file: sound5A,
  },
  {
    note: 'A#',
    file: sound5Bb,
  },
  {
    note: 'B',
    file: sound5B,
  },
];

export default soundFiles;
