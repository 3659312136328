import React from 'react';
import propTypesHelper from 'utilities/prop-types-helper';
import SettingsTonicInput from 'components/settings/tonic/input';

const SettingsTonic = props => {
  const { setTonic, tonic } = props;

  return (
    <fieldset>
      <div className="form-group">
        <label htmlFor="tonic">Tonic</label>
        <SettingsTonicInput
          className="form-control"
          tonic={tonic}
          setTonic={setTonic}
          trackingCategory="Settings"
        />
      </div>
    </fieldset>
  );
};

SettingsTonic.propTypes = propTypesHelper({
  tonic: true,
  setTonic: true,
});

export default SettingsTonic;
