const buildHash = (params = {}) => {
  const bits = [];
  Object.entries(params).forEach(entry => {
    const [key, value] = entry;
    bits.push(`${key}/${value}`);
  });
  return `#/${bits.join('/')}`;
};

const updateHash = (params = {}) => {
  window.location.hash = buildHash(params);
};

const get = key => {
  const { hash } = window.location;
  const bits = hash.split('/');
  const index = bits.findIndex(bit => bit === key);
  return bits[index + 1] || null;
};

export default {
  buildHash,
  updateHash,
  get,
};
