import React, { Component } from 'react';
import reactGA from 'react-ga';
import propTypesHelper from 'utilities/prop-types-helper';
import FontAwesomeIcon from 'utilities/font-awesome';
import Modal from 'utilities/modal';
import SettingsDegreesEditor from 'components/settings/degrees/editor';

class SettingsDegrees extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    reactGA.event({
      category: 'Settings',
      action: 'Degrees',
      label: 'Open modal',
    });
    this.setState({
      modalOpen: true,
    });
  }

  closeModal() {
    reactGA.event({
      category: 'Settings',
      action: 'Degrees',
      label: 'Close modal',
    });
    this.setState({
      modalOpen: false,
    });
  }

  render() {
    const { setDegrees, degrees } = this.props;
    const { modalOpen } = this.state;

    const degreesText = degrees.join(', ');

    const modalStyle = {
      overlay: {
        zIndex: 2000,
      },
      content: {
        top: '50%',
        bottom: 'auto',
        transform: 'translateY(-50%)',
      },
    };

    return (
      <fieldset>
        <div className="form-group">
          <label htmlFor="staticDegrees">Degrees</label>
          <div className="row">
            <div className="col-10">
              <input
                type="text"
                readOnly
                className="form-control-plaintext"
                id="staticDegrees"
                value={degreesText}
                onClick={this.openModal}
              />
            </div>
            <div className="col-2">
              <button
                type="button"
                onClick={this.openModal}
                className="btn btn-default"
              >
                <FontAwesomeIcon icon="edit" />
              </button>
            </div>
          </div>
        </div>
        <Modal isOpen={modalOpen} style={modalStyle}>
          <SettingsDegreesEditor
            degrees={degrees}
            setDegrees={setDegrees}
            closeModal={this.closeModal}
          />
        </Modal>
      </fieldset>
    );
  }
}
SettingsDegrees.propTypes = propTypesHelper({
  setDegrees: true,
  degrees: true,
});

export default SettingsDegrees;
