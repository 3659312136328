import React from 'react';
import scales from 'config/scales';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import propTypesHelper from 'utilities/prop-types-helper';
import reactGA from 'react-ga';

const SettingsQuickDropdown = props => {
  const { setDegrees, degrees } = props;

  const handleClick = scaleDegrees => {
    reactGA.event({
      category: 'Header',
      action: 'Quick scale',
      label: scaleDegrees.join(', '),
    });
    setDegrees(scaleDegrees);
  };

  let selected = 'Custom';

  const currentDegreesString = degrees.join(',');

  const options = scales.map(scale => {
    const { degrees: scaleDegrees, label } = scale;
    const degreesString = scaleDegrees.join(',');
    if (degreesString === currentDegreesString) {
      selected = label;
    }
    return (
      <Dropdown.Item
        as="button"
        key={degreesString}
        onClick={() => handleClick(scaleDegrees)}
      >
        {label}
      </Dropdown.Item>
    );
  });

  return (
    <DropdownButton variant="secondary" title={`Scale: ${selected}`}>
      {options}
    </DropdownButton>
  );
};

SettingsQuickDropdown.propTypes = propTypesHelper({
  setDegrees: true,
  degrees: true,
});

export default SettingsQuickDropdown;
