import React, { Component } from 'react';
import classNames from 'classnames';
import reactGA from 'react-ga';
import interactions from 'utilities/interactions';
// import Tooltip from 'rc-tooltip';
// import FontAwesomeIcon from 'utilities/font-awesome';
import propTypesHelper from 'utilities/prop-types-helper';

class ChordSingle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasChanged: false,
      intervals: null,
    };
    this.handleAnimationEnd = this.handleAnimationEnd.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleFocus = this.handleMouseOver.bind(this);
    this.handleBlur = this.handleMouseLeave.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let hasChanged = false;
    const nextDegrees = nextProps.intervals;
    const prevDegrees = prevState.intervals;
    const { chord } = nextProps;
    if (prevState.intervals) {
      const hasChord = chord.hasIntervals(nextDegrees);
      const hadChord = chord.hasIntervals(prevDegrees);
      hasChanged = hasChord !== hadChord;
    }
    return {
      hasChanged,
      intervals: nextDegrees,
    };
  }

  handleAnimationEnd() {
    this.setState({
      hasChanged: false,
    });
  }

  handleClick() {
    const {
      chord,
      setActiveChords,
      isStickyChord,
      isActiveChord,
      getActiveChord,
    } = this.props;
    if (
      !isActiveChord(chord, 'right') &&
      (!getActiveChord('left') || !isStickyChord('left'))
    ) {
      setActiveChords('left', chord, true);
      reactGA.event({
        category: 'Chords',
        action: 'Affix',
        label: 'left',
      });
    } else if (isActiveChord(chord, 'left') && isStickyChord('left')) {
      setActiveChords('left', null);
      reactGA.event({
        category: 'Chords',
        action: 'Unfix',
        label: 'left',
      });
    } else if (isActiveChord(chord, 'right') && isStickyChord('right')) {
      setActiveChords('right', null);
      reactGA.event({
        category: 'Chords',
        action: 'Unfix',
        label: 'right',
      });
    } else if (
      interactions.USER_CAN_TOUCH &&
      isStickyChord('left') &&
      isStickyChord('right')
    ) {
      setActiveChords(['middle', chord, true]);
    } else {
      reactGA.event({
        category: 'Chords',
        action: 'Affix',
        label: 'right',
      });
      setActiveChords(['right', chord, true], ['middle', null]);
    }
  }

  handleMouseOver() {
    if (!interactions.USER_CAN_TOUCH) {
      const {
        chord,
        setActiveChords,
        isStickyChord,
        isActiveChord,
      } = this.props;
      if (!isActiveChord(chord)) {
        if (!isStickyChord('left')) {
          setActiveChords('left', chord);
        } else if (!isStickyChord('right')) {
          setActiveChords('right', chord);
        } else {
          setActiveChords('middle', chord);
        }
      }
    }
  }

  handleMouseLeave() {
    if (!window.USER_CAN_TOUCH) {
      const {
        chord,
        setActiveChords,
        isStickyChord,
        isActiveChord,
      } = this.props;
      if (!isStickyChord('left') && isActiveChord(chord, 'left')) {
        setActiveChords('left', null);
      } else if (!isStickyChord('right') && isActiveChord(chord, 'right')) {
        setActiveChords('right', null);
      } else if (!isStickyChord('middle') && isActiveChord(chord, 'middle')) {
        setActiveChords('middle', null);
      }
    }
  }

  render() {
    const {
      chord,
      intervals,
      scaleDegrees,
      startingDegree,
      getActiveChord,
      activeNote,
    } = this.props;

    const hasChord = chord.hasIntervals(intervals);

    const { hasChanged } = this.state;

    const cls = {
      btn: true,
      'btn-link': true,
      'chord-single': true,
      'cursor-pointer': true,
      invisible: !hasChord,
      highlightable: true,
      animated: hasChanged,
      heartBeat: hasChanged,
    };

    if (hasChord) {
      const leftChord = getActiveChord('left');
      const rightChord = getActiveChord('right');

      chord.setNotes(startingDegree.getLabel(), scaleDegrees);
      const compare = [];
      if (leftChord) {
        cls['active-chord-left'] =
          leftChord.id === chord.id &&
          (!activeNote || chord.hasNote(activeNote));
        compare.push(leftChord);
      }
      if (rightChord) {
        cls['active-chord-right'] =
          rightChord.id === chord.id &&
          (!activeNote || chord.hasNote(activeNote));
        compare.push(rightChord);
      }

      if (!activeNote || (activeNote && chord.hasNote(activeNote))) {
        const compareTo = chord.compareTo(compare);
        if (compare.length) {
          switch (compareTo.length) {
            case 1:
              {
                const first = compareTo[0];
                const { diff, common } = first;
                if (diff === 0) {
                  cls[`inversion-0`] = true;
                } else if (
                  diff === first.added.length &&
                  !first.removed.length
                ) {
                  cls[`inversion-removed-${diff}`] = true;
                } else if (
                  diff === first.removed.length &&
                  !first.added.length
                ) {
                  cls[`inversion-added-${diff}`] = true;
                } else if (
                  diff === first.removed.length &&
                  diff === first.added.length
                ) {
                  cls[`inversion-changed-${diff}`] = true;
                } else if (common.length) {
                  cls[`inversion-common-${common.length}`] = true;
                }
              }
              break;
            case 2: {
              const [left, right] = compareTo;
              if (left.diff === 0 || right.diff === 0) {
                cls[`inversion-0`] = true;
              } else if (left.common.join(',') === right.common.join(',')) {
                cls[`inversion-changed-${(left.diff + right.diff) / 2}`] = true;
              } else if (left.common.length === right.common.length) {
                cls[`inversion-added-${(left.diff + right.diff) / 2}`] = true;
              }

              break;
            }
            default:
              break;
          }
        }
      } else if (activeNote && !chord.hasNote(activeNote)) {
        cls.faded = true;
      }
    }
    chord.intervals.forEach(interval => {
      cls[`has-${interval.toLowerCase()}`] = true;
    });

    return hasChord ? (
      <button
        id={chord.getSlug()}
        type="button"
        className={classNames(cls)}
        onFocus={this.handleMouseOver}
        onBlur={this.handleMouseLeave}
        onMouseEnter={this.handleMouseOver}
        onMouseLeave={this.handleMouseLeave}
        onAnimationEnd={this.handleAnimationEnd}
        onClick={this.handleClick}
      >
        <span>{chord.getLabel()}</span>
      </button>
    ) : (
      <div className="chord-single">&nbsp;</div>
    );
  }
}
// {/*<Tooltip trigger={['click']} overlay={tooltipContent}>*/}

ChordSingle.propTypes = propTypesHelper({
  chord: true,
  intervals: true,
  isDegrees: true,
  scaleDegrees: true,
  setPlaySounds: true,
  startingDegree: true,
});

export default ChordSingle;
