import React from 'react';
import ScaleDegree from 'utilities/degree';
import {
  sharpNote2Flat,
  flatNote2Sharp,
  displayIntervalAs,
} from 'utilities/notation';
import { inArray } from 'utilities/general';
import slugify from 'slugify';

class Chord {
  constructor(props = {}) {
    const { name, intervals, symbol, lower, header, intervalsLabel } = props;
    Object.entries({ name, intervals, symbol }).forEach(entry => {
      const [key, value] = entry;
      if (value === undefined) {
        throw new Error(`${key} is required`);
      }
    });

    this.name = name;
    this.intervals = intervals;
    this.symbol = symbol;
    this.lower = lower || false;
    this.header = header || null;
    this.intervalsLabel = intervalsLabel || null;

    this.rootNote = null;
    this.notes = [];
  }

  getLabel() {
    return (
      <span>
        {this.rootNote}
        {this.symbol}
      </span>
    );
  }

  setNotes(rootNote, scale) {
    this.rootNote = rootNote;
    this.notes = this.findNotes(rootNote, scale);
    this.id = `${rootNote}-${slugify(this.name)}`.toLowerCase();
  }

  getNotes() {
    return this.notes.slice();
  }

  getSlug() {
    return `${this.rootNote} ${this.name}`
      .replace(/\W/g, '-')
      .replace(/-+/, '-')
      .toLowerCase();
  }

  hasNote(note) {
    return this.getNotes().includes(note);
  }

  hasIntervals(intervals) {
    const find = intervals.map(interval => interval.toUpperCase());
    let i;
    let interval;
    for (i = this.intervals.length - 1; i >= 0; i--) {
      interval = this.intervals[i].toUpperCase();
      if (!find.includes(interval)) {
        return false;
      }
    }
    return true;
  }

  hasInterval(interval) {
    return this.intervals
      .map(item => item.toUpperCase())
      .includes(interval.toUpperCase());
  }

  getIntervalsLabel() {
    const intervals = this.intervalsLabel || this.intervals;
    return intervals.map(interval => (
      <span key={interval} className="chord-interval">
        {displayIntervalAs('numeric', interval)}
      </span>
    ));
  }

  compareTo(chords) {
    const compare = Array.isArray(chords) ? chords : [chords];
    const results = [];

    compare.forEach(chord => {
      const myNotes = this.getNotes().sort();
      const theirNotes = chord.getNotes().sort();

      const comparison = {
        myNotes,
        theirNotes,
        common: myNotes.filter(x => theirNotes.includes(x)),
        removed: myNotes.filter(x => !theirNotes.includes(x)),
        added: theirNotes.filter(x => !myNotes.includes(x)),
        diff: null,
      };
      if (comparison.common.length) {
        comparison.diff = (myNotes.length >= theirNotes.length
          ? myNotes.filter(x => !theirNotes.includes(x))
          : theirNotes.filter(x => !myNotes.includes(x))
        ).length;
      }
      results.push(comparison);
    });
    // if (
    //   results.length &&
    //   (this.rootNote === 'D' && this.name === 'Suspended second')
    //   // ||
    //   // (this.rootNote === 'C' && this.name === 'Major nine') ||
    //   // (this.rootNote === 'C' && this.name === 'Six')
    // ) {
    //   console.log(`${this.rootNote} ${this.name}`, results);
    // }
    return results;
  }

  findNotes(rootNote, scale) {
    const getNoteFittedToScale = chordScaleDegree => {
      let fitted = chordScaleDegree.getLabel();
      if (this.scale) {
        const scaleNotes = scale.map(scaleDegree => scaleDegree.getLabel());
        if (!inArray(fitted, scaleNotes)) {
          if (chordScaleDegree.noteIsSharp()) {
            fitted = sharpNote2Flat(fitted);
          } else if (chordScaleDegree.noteIsFlat()) {
            fitted = flatNote2Sharp(fitted);
          }
        }
      }
      return fitted;
    };

    const notes = [];

    this.intervals.forEach(interval => {
      const chordScaleDegree = new ScaleDegree(rootNote, interval);
      const note = getNoteFittedToScale(chordScaleDegree);
      notes.push(note);
    });
    return notes;
  }
}

export default Chord;
