import { naturalDegrees, flatDegrees } from 'config/notation';

import {
  normaliseDegree,
  getNatural,
  getNaturalDegree,
  buildNaturalScale,
  buildChromaticScale,
  addIntervalToNote,
  degreeIsNatural,
  degreeIsFlat,
  degreeIsSharp,
  noteIsNatural,
  noteIsFlat,
  noteIsSharp,
} from 'utilities/notation';

class ScaleDegree {
  constructor(tonic, degree = 'I') {
    this.tonic = tonic;
    this.degree = degree;

    this.semitonesFromTonic = flatDegrees.indexOf(normaliseDegree(this.degree));
    this.naturalScale = buildNaturalScale(this.tonic);
    this.chromaticScale = buildChromaticScale(this.tonic);

    this.expressDegreeAs = this.getExpressDegreeAs();
    this.expressNoteAs = this.getExpressNoteAs();
    this.note = addIntervalToNote(this.tonic, normaliseDegree(degree));
  }

  getIntervalFrom(degree) {
    let diff =
      flatDegrees.indexOf(normaliseDegree(this.getDegree())) -
      flatDegrees.indexOf(normaliseDegree(degree));
    if (diff < 0) {
      diff += flatDegrees.length;
    }
    return flatDegrees[diff] || '-';
  }

  getSemitonesFromTonic() {
    return this.semitonesFromTonic;
  }

  degreeIsNatural() {
    return degreeIsNatural(this.degree);
  }

  degreeIsFlat() {
    return degreeIsFlat(this.degree);
  }

  degreeIsSharp() {
    return degreeIsSharp(this.degree);
  }

  noteIsNatural() {
    return noteIsNatural(this.note);
  }

  noteIsFlat() {
    return noteIsFlat(this.note);
  }

  noteIsSharp() {
    return noteIsSharp(this.note);
  }

  expressedNoteIsNatural() {
    return !!this.getLabel().match(/^[a-g]+$/i);
  }

  expressedNoteIsFlat() {
    return !!this.getLabel().match(/^[a-g]b+$/i);
  }

  expressedNoteIsSharp() {
    return !!this.getLabel().match(/^[a-g]#+$/i);
  }

  getDegree() {
    return this.degree;
  }

  getNote() {
    return this.note;
  }

  getExpressDegreeAs() {
    const naturalDegree = this.getNaturalDegree();

    const degreePosition = naturalDegrees.indexOf(naturalDegree);

    return this.naturalScale[degreePosition];
  }

  getExpressNoteAs() {
    const naturalDegree = this.getNaturalDegree();
    const degreePosition = naturalDegrees.indexOf(naturalDegree);

    return this.naturalScale[degreePosition];
  }

  getNatural() {
    return getNatural(this.note);
  }

  getNaturalDegree() {
    return getNaturalDegree(this.degree);
  }

  expressAs(expressAs) {
    const expressPosition = this.chromaticScale.indexOf(expressAs);
    const myPosition = this.chromaticScale.indexOf(this.note);

    let diff = myPosition - expressPosition;

    if (diff === 0) {
      return expressAs;
    }

    if (diff > 2) {
      diff = -12 + diff;
    } else if (diff < -2) {
      diff = 12 + diff;
    }

    const modifier = diff < 0 ? 'b' : '#';
    const modification = ''.padStart(Math.abs(diff), modifier);

    return `${expressAs}${modification}`;
  }

  getLabel() {
    const naturalNote = this.getNatural();
    if (naturalNote === this.expressNoteAs) {
      return this.note;
    }
    return this.expressAs(this.expressNoteAs);
  }
}

export default ScaleDegree;
