import React from 'react';
import reactGA from 'react-ga';
import classNames from 'classnames';
import FontAwesomeIcon from 'utilities/font-awesome';

const ChordInfoBox = ({
  comparison,
  position,
  renderActions,
  getActiveChord,
  ...props
}) => {
  const chord = getActiveChord(position);

  if (!chord) {
    return '';
  }

  const handleClearChord = () => {
    const { setActiveChords } = props;
    setActiveChords(['middle', null], [position, null]);
    reactGA.event({
      category: 'Chords',
      action: 'Unfix',
      label: position,
    });
  };

  const handlePlayNotes = () => {
    const { setPlaySounds, playRootAfterArpeggio } = props;
    let notes = chord.getNotes();
    if (playRootAfterArpeggio) {
      notes = notes.concat(chord.getNotes().slice(0, 1));
    }
    setPlaySounds(notes, true);
    reactGA.event({
      category: 'Sound',
      action: 'Arpeggio',
      label: notes.join(', '),
    });
  };

  const handlePlayChord = () => {
    const { setPlaySounds } = props;
    const notes = chord.getNotes().concat(chord.getNotes().slice(0, 1));
    setPlaySounds([notes.join(',')], true);
    reactGA.event({
      category: 'Sound',
      action: 'Chord',
      label: notes.join(', '),
    });
  };
  const noteContent = note => {
    const { playSounds } = props;
    let playing = false;

    if (playSounds) {
      const [playingNotes] = playSounds;
      if (playingNotes.length) {
        if (playingNotes[0] === note) {
          playing = true;
        } else if (playingNotes[0].match(',')) {
          const notes = playingNotes[0].split(',');
          if (notes.includes(note)) {
            playing = true;
          }
        }
      }
    }
    const cls = {
      note: true,
      'text-info':
        comparison && comparison.added && comparison.added.includes(note),
      'text-danger':
        comparison && comparison.removed && comparison.removed.includes(note),
      'text-success':
        comparison && comparison.common && comparison.common.includes(note),
      heartBeat: playing,
      animated: playing,
    };

    const className = classNames(cls);
    return (
      <span key={note} className={className}>
        {note}
      </span>
    );
  };

  const getTable = () => {
    const notes = chord
      .getNotes()
      .map(noteContent)
      .map((content, ix) => <td key={ix}>{content}</td>);
    // const intervals = chord
    //   .getIntervalsLabel()
    //   .map((content, ix) => <td key={ix}>{content}</td>);

    return (
      <table className="table table-sm notes">
        <tbody>
          <tr>{notes}</tr>
        </tbody>
      </table>
    );
  };

  const actions = renderActions ? (
    <div className="actions row">
      <div className="col">
        <button
          className="btn btn-sm btn-info mr-2"
          type="button"
          aria-label="Play arpeggio"
          onClick={handlePlayNotes}
        >
          <span className="d-none d-sm-inline">Arpeggio</span>
          <span className="d-sm-none">Arp.</span>
          <FontAwesomeIcon className="ml-2" icon="volume-up" />
        </button>
      </div>
      <div className="col">
        <button
          className="btn btn-sm btn-info"
          type="button"
          onClick={handlePlayChord}
          aria-label="Play chord"
        >
          <span className="d-none d-sm-inline">Chord</span>
          <span className="d-sm-none">Crd.</span>
          <FontAwesomeIcon className="ml-2" icon="volume-up" />
        </button>
      </div>
    </div>
  ) : null;

  const table = getTable();
  return (
    <div className="info-box row" id={`info-box-${position}`}>
      <button
        type="button"
        onClick={handleClearChord}
        className="btn btn-link  clear"
      >
        <FontAwesomeIcon className="text-danger" icon="times" />
      </button>

      <div className="chord-name col-3 col-sm-12">
        <h3>{chord.getLabel()}</h3>
      </div>
      <div className="col-8 col-sm-12">
        {table}
        {actions}
      </div>
    </div>
  );
};

export default ChordInfoBox;
