import React from 'react';

const year = new Date().getFullYear();

const steps = [
  {
    slug: 'HOME',
    content: (
      <div>
        <p>
          Chordinatr helps to explore the relationships between chords and
          scales.
        </p>
        <p>
          It helps to visualise how flattening and sharpening degrees changes
          the intervals within the scale, and the chords that can be built from
          them.
        </p>
        <p>
          It also helps to find potential chords to add colour in your
          progressions, for substition and passing chords.
        </p>
        <p>
          Please report any bugs / issues / suggestions on{' '}
          <a
            href="https://gitlab.com/chordinatr/issues/issues"
            rel="noopener noreferrer"
            target="_blank"
          >
            Gitlab.
          </a>
        </p>
        <p>
          <small>Copyright &copy; {year} OllyOllyOlly Ltd</small>
        </p>
      </div>
    ),
    placement: 'center',
    target: 'body',
    title: 'Chordinatr',
    styles: { tooltipContainer: { textAlign: 'left' } },
    locale: {
      next: 'Take a tour...',
    },
  },
  {
    slug: 'CHORDS-INTRO',
    content: 'You can see the chords that can be taken from the scale here',
    placement: 'top',
    target: '#chord-collection-triads',
    title: 'Chords',
  },
  {
    slug: 'CHORDS-LEFT-CLICK',
    content: 'Click on a chord to see information about it',
    placement: 'right',
    target: '#c-major',
    spotlightClicks: true,
    hideFooter: true,
  },
  {
    slug: 'CHORDS-LEFT-INFO',
    content: 'You can see information about the chord here',
    placement: 'top',
    target: '#chord-info-left',
    hideBackButton: true,
  },
  {
    slug: 'CHORDS-LEFT-HIGHLIGHT',
    content: (
      <div>
        <p>
          When a chord is selected, similar chords are highlighted. The stronger
          the highlight, the closer the relationship.
        </p>
        <p>
          <strong className="text-green">Green</strong> means the chord is an
          inversion of the selected chord - it has exactly the same notes, in a
          different order.
        </p>
        <p>
          <strong className="text-blue">Blue</strong> means the chord is
          contains <strong>all</strong> the notes of the selected chord, plus
          some extra.
        </p>
        <p>
          <strong className="text-orange">Orange</strong> means the chord
          contains some of the notes as the selected chord, but some have been
          removed.
        </p>
        <p>
          <strong className="text-yellow">Yellow</strong> means the chord
          contains the same number of notes as the selected chords, and have
          some in common, but some have been changed.
        </p>
        <p>
          <strong className="text-purple">Purple</strong> means the chord shares
          some notes in common with the selected chord.
        </p>
      </div>
    ),
    styles: {
      tooltipContainer: { textAlign: 'left' },
      options: { width: '100%' },
    },
    placement: 'top',
    target: '.chords',
  },
  {
    slug: 'CHORDS-RIGHT-CLICK',
    content: 'Click on another chord',
    placement: 'left',
    target: '#e-minor',
    spotlightClicks: true,
    hideFooter: true,
  },
  {
    slug: 'CHORDS-RIGHT-DIFFERENCES',
    content: (
      <div>
        <p>You can see the differences between the two selected chords here.</p>
        <p>
          <strong className="text-green">Green</strong> notes are present in
          both chords.
        </p>
        <p>
          <strong className="text-red">Red</strong> notes are present in the
          left-hand chord but not the right.
        </p>
        <p>
          <strong className="text-blue">Blue</strong> notes are present in the
          right-hand chord but not the left.
        </p>
      </div>
    ),
    styles: { tooltipContainer: { textAlign: 'left' } },
    placement: 'top',
    target: '#chord-info',
    hideBackButton: true,
  },
  {
    content: '',
    target: 'body',
  },
  {
    slug: 'CHORDS-RIGHT-HIGHLIGHT',
    content: (
      <div>
        <p>
          When two chords are selected, chords that share notes with{' '}
          <strong>both</strong> of them will be highlighted.
        </p>
        <p>
          <strong className="text-green">Green</strong> chords are an inversion
          of one or both selected chords.
        </p>
        <p>
          <strong className="text-yellow">Yellow</strong> chords have the same
          notes in common with <strong>both</strong> selected chords.
        </p>
        <p>
          <strong className="text-blue">Blue</strong> chords have the same
          number notes in common with <strong>both</strong> selected chords, but
          the notes themselves are different.
        </p>
      </div>
    ),
    styles: {
      tooltipContainer: { textAlign: 'left' },
      options: { width: '100%' },
    },
    placement: 'top',
    target: '.chords',
  },
  {
    slug: 'SCALE-SHOW-CHORDS',
    title: 'Scale',
    content:
      "Hover over the notes of the scale to show only chords which contain them.  Clicking on the notes will 'stick' them",
    placement: 'bottom',
    spotlightClicks: true,
    target: '.degrees thead',
  },
  {
    slug: 'SCALE-EDIT',
    content: 'Edit the scale from which the chords are built here',
    placement: 'bottom',
    spotlightClicks: true,
    target: '#edit-degrees-trigger',
    hideFooter: true,
  },
  {
    slug: 'SCALE-TABLE',
    content: 'This table shows the intervals between each degree of the scale',
    placement: 'bottom',
    target: '.degrees',
    hideBackButton: true,
  },
  {
    slug: 'SCALE-INTERVAL',
    content: 'The interval between III (E) and a V (G) is a minor third',
    placement: 'right',
    target: '.degrees .intersection-iii-v',
  },
  {
    slug: 'SCALE-FLATTEN',
    content: 'Flatten the third degree of the scale',
    placement: 'right',
    target: '.degrees .degree-row-e .control-flatten',
    hideFooter: true,
    spotlightClicks: true,
  },
  {
    slug: 'SCALE-INTERVAL-CHANGED',
    content: 'The interval between bIII (Eb) and V G is a major third',
    placement: 'right',
    target: '.degrees .intersection-iii-v',
    hideBackButton: true,
  },
  {
    slug: 'SCALE-TABLE-CHANGED',
    content: 'The available chords have changed with the scale',
    placement: 'top',
    target: '.chords',
  },
  {
    slug: 'SETTINGS',
    content:
      'Change the tonic, quickly select predefined scales, and adjust other settings here',
    placement: 'bottom',
    target: '.chordinatr-header .tools',
  },
  {
    slug: 'OUTRO',
    content: <div>Have fun!</div>,
    placement: 'center',
    target: 'body',
    locale: {
      last: 'Get to it',
    },
  },
];

const getStepIndexBySlug = slug => {
  return steps.findIndex(step => step.slug === slug.toUpperCase());
};

export { getStepIndexBySlug };

export default steps;
