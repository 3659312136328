import React from 'react';
import ScaleDegreeControlsModifier from 'components/degrees/controls/modifier';
import ScaleDegreeSingle from 'components/degrees/single';
import propTypesHelper from 'utilities/prop-types-helper';

const DegreesControls = props => {
  const { scaleDegree, alterDegree } = props;

  const isFirst = scaleDegree.getNaturalDegree() === 'I';
  const isSeventh = scaleDegree.getNaturalDegree() === 'VII';

  return (
    <th scope="row">
      <div className="degrees-cell-inner">
        <div className="btn-group-vertical">
          <ScaleDegreeControlsModifier
            className="control-flatten"
            disabled={isFirst}
            isActive={scaleDegree.degreeIsFlat()}
            modifier="b"
            alterDegree={alterDegree}
          />
          <ScaleDegreeControlsModifier
            className="control-sharpen"
            disabled={isFirst || isSeventh}
            isActive={scaleDegree.degreeIsSharp()}
            modifier="#"
            alterDegree={alterDegree}
          />
        </div>
        <ScaleDegreeSingle scaleDegree={scaleDegree} label="combo" />
      </div>
    </th>
  );
};

DegreesControls.propTypes = propTypesHelper({
  scaleDegree: true,
  alterDegree: true,
});

export default DegreesControls;
