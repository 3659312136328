const notes = ['A', 'A#', 'B', 'C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#'];
const degrees = {
  bI: 11,
  I: 0,
  '#I': 1,
  bII: 1,
  II: 2,
  '#II': 3,
  bIII: 3,
  III: 4,
  '#III': 5,
  bIV: 4,
  IV: 5,
  '#IV': 6,
  bV: 6,
  V: 7,
  '#V': 8,
  bVI: 8,
  VI: 9,
  '#VI': 10,
  bVII: 10,
  VII: 11,
  '#VII': 0,
};

const flatDegrees = Object.keys(degrees).filter(
  degree => !degree.match(/^(#[vi]+|bIV?)$/i)
);

const naturalNotes = notes.filter(note => note.length === 1);
const naturalDegrees = flatDegrees.filter(degree => !degree.match(/[b#]/));

const abbrvNameIntervalMap = {
  I: '-',
  bII: 'm2',
  II: 'M2',
  bIII: 'm3',
  III: 'M3',
  IV: '4',
  bV: 'TT',
  V: '5',
  bVI: 'm6',
  VI: 'M6',
  bbVII: 'M6',
  bVII: 'm7',
  VII: 'M7',
};

export {
  notes,
  degrees,
  flatDegrees,
  abbrvNameIntervalMap,
  naturalNotes,
  naturalDegrees,
};
