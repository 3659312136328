import PropTypes from 'prop-types';

import ScaleDegree from 'utilities/degree';
import Chord from 'utilities/chord';

const chordShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  symbol: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  lower: PropTypes.isRequired,
  intervals: PropTypes.arrayOf(PropTypes.string).isRequired,
  intervalsLabel: PropTypes.arrayOf(PropTypes.string),
});

const playSoundsArray = PropTypes.arrayOf(PropTypes.any);

export default {
  leftChord: PropTypes.instanceOf(Chord),
  alterDegree: PropTypes.func,
  checked: PropTypes.bool,
  chord: chordShape,
  chords: PropTypes.arrayOf(chordShape),
  className: PropTypes.string,
  closeModal: PropTypes.func,
  rightChord: PropTypes.instanceOf(Chord),
  degrees: PropTypes.arrayOf(PropTypes.string),
  displayIntervalsAs: PropTypes.oneOf(['abbrv', 'roman', 'semitones']),
  highlight: PropTypes.arrayOf(PropTypes.string),
  intervals: PropTypes.arrayOf(PropTypes.string),
  isDegrees: PropTypes.bool,
  label: PropTypes.string,
  playSounds: playSoundsArray,
  scaleDegree: PropTypes.instanceOf(ScaleDegree),
  scaleDegrees: PropTypes.arrayOf(PropTypes.instanceOf(ScaleDegree)),
  setDegrees: PropTypes.func,
  setDisplayIntervalsAs: PropTypes.func,
  setHighlight: PropTypes.func,
  setIsDegrees: PropTypes.func,
  setMenuIsOpen: PropTypes.func,
  setPlaySounds: PropTypes.func,
  setLeftChord: PropTypes.func,
  setRightChord: PropTypes.func,
  setTonic: PropTypes.func,
  startingDegree: PropTypes.instanceOf(ScaleDegree),
  stickLeftChord: PropTypes.bool,
  tonic: PropTypes.string,
};
