import React from 'react';
import propTypesHelper from 'utilities/prop-types-helper';

import SettingsRadio from 'components/settings/radio';

const SettingsDisplayIntervalsAs = props => {
  const { displayIntervalsAs, setDisplayIntervalsAs } = props;

  return (
    <fieldset>
      <div>
        <strong>Display intervals as:</strong>
      </div>
      <SettingsRadio
        checked={displayIntervalsAs === 'abbrv'}
        label="Abbreviated names"
        setter={setDisplayIntervalsAs}
        value="abbrv"
        id="display-intervals-as-abbrv"
      />
      <SettingsRadio
        checked={displayIntervalsAs === 'roman'}
        label="Roman numerals"
        setter={setDisplayIntervalsAs}
        value="roman"
        id="display-intervals-as-roman"
      />
      <SettingsRadio
        checked={displayIntervalsAs === 'semitones'}
        label="Semitones from tonic"
        setter={setDisplayIntervalsAs}
        value="semitones"
        id="display-intervals-as-semitones"
      />
    </fieldset>
  );
};

SettingsDisplayIntervalsAs.propTypes = propTypesHelper({
  displayIntervalsAs: true,
  setDisplayIntervalsAs: true,
});

export default SettingsDisplayIntervalsAs;
