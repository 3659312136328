import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import propTypesHelper from 'utilities/prop-types-helper';

const ScaleDegreeControlsModifier = props => {
  const { isActive, modifier, alterDegree, disabled, className } = props;

  const handleAlteration = event => {
    const { target } = event;
    target.blur();
    alterDegree(isActive ? '' : modifier);
  };

  return (
    <button
      type="button"
      className={classNames({
        btn: true,
        'btn-sm': true,
        'degree-control': true,
        'btn-outline-primary': !isActive,
        'btn-primary': isActive,
        [className]: true,
      })}
      onClick={handleAlteration}
      disabled={disabled}
    >
      {modifier}
    </button>
  );
};

ScaleDegreeControlsModifier.propTypes = propTypesHelper(
  {
    alterDegree: true,
  },
  {
    isActive: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    modifier: PropTypes.string.isRequired,
  }
);

export default ScaleDegreeControlsModifier;
