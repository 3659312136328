import React, { Component } from 'react';
import reactGA from 'react-ga';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ChordsHeader from 'components/chords/header';
import ChordsRow from 'components/chords/row';
import ScaleDegree from 'utilities/degree';
import propTypesHelper from 'utilities/prop-types-helper';
import Chord from 'utilities/chord';

class ChordsCollection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: true,
    };
    this.hasChords = false;
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleMenu() {
    const { menuOpen } = this.state;
    if (this.hasChords) {
      this.setState({
        menuOpen: !menuOpen,
      });
      reactGA.event({
        category: 'Chords',
        action: 'Collection',
        label: !menuOpen ? 'Open' : 'Close',
      });
    }
  }

  render() {
    const {
      chords,
      degrees,
      highlight,
      isDegrees,
      label,
      setHighlight,
      tonic,
      displayIntervalsAs,
      scaleDegrees,
      setPlaySounds,
      activeNote,

      setActiveChords,
      isActiveChord,
      isStickyChord,
      getActiveChord,
    } = this.props;

    const { menuOpen } = this.state;

    this.hasChords = false;

    const rows = degrees.map((degree, ix) => {
      const startingDegree = new ScaleDegree(tonic, degree);

      const intervals = scaleDegrees.map(scaleDegree =>
        scaleDegree.getIntervalFrom(startingDegree.getDegree())
      );

      const chordObjects = [];
      chords.forEach(chord => {
        const chordObject = new Chord(chord);
        if (!this.hasChords) {
          this.hasChords = chordObject.hasIntervals(intervals);
        }
        chordObjects.push(chordObject);
      });

      const key = `chords-${label}-${ix}`;

      return (
        <ChordsRow
          key={key}
          scaleDegrees={scaleDegrees}
          startingDegree={startingDegree}
          intervals={intervals}
          isDegrees={isDegrees}
          tonic={tonic}
          chords={chordObjects}
          highlight={highlight}
          setHighlight={setHighlight}
          setPlaySounds={setPlaySounds}
          getActiveChord={getActiveChord}
          setActiveChords={setActiveChords}
          isActiveChord={isActiveChord}
          isStickyChord={isStickyChord}
          activeNote={activeNote}
        />
      );
    });

    const collectionClass = classNames({
      'chord-collection': true,
      in: this.hasChords && menuOpen,
    });

    return (
      <div className="chord-collection-wrap">
        <button
          type="button"
          className="chord-collection-label btn btn-info btn-sm"
          disabled={!this.hasChords}
          onClick={this.toggleMenu}
        >
          <div className="">{label}</div>
        </button>
        <div
          className={collectionClass}
          id={`chord-collection-${label.toLowerCase()}`}
        >
          <table className="table table-striped">
            <ChordsHeader
              chords={chords}
              displayIntervalsAs={displayIntervalsAs}
            />
            <tbody>{rows}</tbody>
          </table>
        </div>
      </div>
    );
  }
}

ChordsCollection.defaultProps = {
  highlight: '',
};

ChordsCollection.propTypes = propTypesHelper(
  {
    chords: true,
    degrees: true,
    displayIntervalsAs: true,
    highlight: false,
    isDegrees: true,
    setPlaySounds: true,
    tonic: true,
  },
  {
    label: PropTypes.string.isRequired,
  }
);

export default ChordsCollection;
