import React, { Component } from 'react';

import ScaleDegree from 'utilities/degree';
import chords from 'config/chords';
import ChordsCollection from 'components/chords/collection';
import DegreesSingle from 'components/degrees/single';
import propTypesHelper from 'utilities/prop-types-helper';
import ChordInfo from 'components/chords/info';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';

class ChordsContainer extends Component {
  constructor(props) {
    super(props);
    this.infoBoxSentinel = React.createRef();
  }

  render() {
    const {
      degrees,
      tonic,
      isDegrees,
      highlight,
      setHighlight,
      displayIntervalsAs,
      setPlaySounds,
      scaleDegrees,
      isActiveChord,
      isStickyChord,
      setActiveChords,
      getActiveChord,
      playSounds,
      activeNote,
      playRootAfterArpeggio,
    } = this.props;
    const sets = chords.getSets();

    const chordsSets = Object.entries(sets).map(set => {
      const [label, chordsInSet] = set;
      return (
        <ChordsCollection
          key={label}
          tonic={tonic}
          degrees={degrees}
          isDegrees={isDegrees}
          label={label}
          chords={chordsInSet}
          highlight={highlight}
          setHighlight={setHighlight}
          displayIntervalsAs={displayIntervalsAs}
          scaleDegrees={scaleDegrees}
          setPlaySounds={setPlaySounds}
          getActiveChord={getActiveChord}
          setActiveChords={setActiveChords}
          isActiveChord={isActiveChord}
          isStickyChord={isStickyChord}
          activeNote={activeNote}
          playRootAfterArpeggio={playRootAfterArpeggio}
        />
      );
    });

    const headerTableRows = degrees.map((degree, ix) => {
      const scaleDegree = new ScaleDegree(tonic, degree);
      const key = `${tonic}-${degree}-${ix}`;
      return (
        <tr key={key}>
          <th scope="row">
            <DegreesSingle
              scaleDegree={scaleDegree}
              isDegrees={isDegrees}
              isStartingDegree
              label="combo"
            />
          </th>
        </tr>
      );
    });

    const headerTable = (
      <table className="table table-striped chords-header-table">
        <thead>
          <tr>
            <th>Chords</th>
          </tr>
        </thead>
        <tbody>{headerTableRows}</tbody>
      </table>
    );

    const chordInfoClass = classNames({
      'chord-info': true,
      'container-fluid': true,
    });

    // const ForwardChordInfo = React.forwardRef((props, ref) => (
    //   <ChordInfo
    //     setPlaySounds={setPlaySounds}
    //     playSounds={playSounds}
    //     getActiveChord={getActiveChord}
    //     setActiveChords={setActiveChords}
    //     chordTableRef={ref}
    //     isStickyChord={isStickyChord}
    //   />
    // ));

    return (
      <div className="chords" ref={this.chordTableRef}>
        <div className="chords-table">
          {headerTable}
          {chordsSets}
        </div>
        <div ref={this.infoBoxSentinel} id="info-box-sentinel" />
        <CSSTransition
          classNames="fade"
          timeout={200}
          in={!!(getActiveChord('left') || getActiveChord('right'))}
        >
          <div className={chordInfoClass}>
            <ChordInfo
              setPlaySounds={setPlaySounds}
              playSounds={playSounds}
              getActiveChord={getActiveChord}
              setActiveChords={setActiveChords}
              infoBoxSentinel={this.infoBoxSentinel}
              isStickyChord={isStickyChord}
              playRootAfterArpeggio={playRootAfterArpeggio}
            />
          </div>
        </CSSTransition>
      </div>
    );
  }
}

ChordsContainer.defaultProps = {
  highlight: '',
  leftChord: null,
  rightChord: null,
};

ChordsContainer.propTypes = propTypesHelper({
  degrees: true,
  displayIntervalsAs: true,
  highlight: false,
  isDegrees: true,
  playSounds: true,
  scaleDegrees: true,
  setPlaySounds: true,
  tonic: true,
});

export default ChordsContainer;
