import React, { Component } from 'react';
import classNames from 'classnames';

import DegreesContainer from 'components/degrees/container';
import ChordsContainer from 'components/chords/container';
import Settings from 'components/settings';
import Header from 'components/header';
import reactGA from 'react-ga';
import 'utilities/font-awesome';
import config from 'config/global';
import Sounds from 'components/sounds';
import ScaleDegree from 'utilities/degree';
import Tour from 'components/tour';
import interactions from 'utilities/interactions';
import hash from 'utilities/hash';

import '../scss/app.scss';

class App extends Component {
  constructor(props) {
    super(props);

    const tonic = 'C';
    const degrees = ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII'];

    const scaleDegrees = degrees.map(degree => new ScaleDegree(tonic, degree));

    this.state = {
      activeNote: null,
      activeNoteSticky: false,
      degrees,
      displayIntervalsAs: 'abbrv',
      highlight: null,
      isDegrees: false,
      menuIsOpen: false,
      playSounds: [[], false, false],
      activeChords: {},
      runTour: false,
      scaleDegrees,
      tonic,
      degreesTableIn: false,
      playRootAfterArpeggio: false,
    };

    this.hasGA = false;

    this.setActiveNote = this.setActiveNote.bind(this);
    this.setDegrees = this.setDegrees.bind(this);
    this.setDisplayIntervalsAs = this.setDisplayIntervalsAs.bind(this);
    this.setIsDegrees = this.setIsDegrees.bind(this);
    this.setMenuIsOpen = this.setMenuIsOpen.bind(this);
    this.setTonic = this.setTonic.bind(this);
    this.setPlaySounds = this.setPlaySounds.bind(this);
    this.setRunTour = this.setRunTour.bind(this);
    this.setPlayRootAfterArpeggio = this.setPlayRootAfterArpeggio.bind(this);

    this.toggleDegreesTable = this.toggleDegreesTable.bind(this);

    this.getActiveChord = this.getActiveChord.bind(this);
    this.setActiveChords = this.setActiveChords.bind(this);
    this.clearActiveChords = this.clearActiveChords.bind(this);
    this.isActiveChord = this.isActiveChord.bind(this);
    this.isStickyChord = this.isStickyChord.bind(this);

    this.initAnalytics();
    this.trackPageview();

    interactions.addListeners();
  }

  componentDidMount() {
    window.addEventListener('hashchange', () => {
      this.updateStateFromHash();
    });
    this.updateStateFromHash();
  }

  componentDidUpdate() {
    const { tonic, degrees } = this.state;
    hash.updateHash({
      tonic,
      degrees: degrees.join(',').replace(/#/g, 's'),
    });
  }

  setActiveNote(activeNote, activeNoteSticky = false) {
    const {
      activeNote: activeNoteCurrent,
      activeNoteSticky: activeNoteStickyCurrent,
    } = this.state;
    if (activeNote !== activeNoteCurrent) {
      this.setState({
        activeNote,
      });
    }
    if (activeNoteSticky !== activeNoteStickyCurrent) {
      this.setState({
        activeNoteSticky,
      });
    }

    if (
      activeNoteSticky ||
      (activeNoteStickyCurrent === true && activeNote === null)
    ) {
      if (activeNote) {
        reactGA.event({
          category: 'Scale',
          action: 'Affix note',
          label: activeNote,
        });
      } else {
        reactGA.event({
          category: 'Scale',
          action: 'Unfix note',
          label: activeNoteCurrent,
        });
      }
    }
  }

  setPlayRootAfterArpeggio(playRootAfterArpeggio) {
    const { playRootAfterArpeggio: current } = this.state;
    if (playRootAfterArpeggio !== current) {
      reactGA.event({
        category: 'Settings',
        action: 'Play root after Arpeggio',
        label: playRootAfterArpeggio ? 'true' : 'false',
      });
      this.setState({
        playRootAfterArpeggio,
      });
    }
  }

  setDisplayIntervalsAs(displayIntervalsAs) {
    const { displayIntervalsAs: current } = this.state;
    if (displayIntervalsAs !== current) {
      reactGA.event({
        category: 'Settings',
        action: 'Interval display',
        label: displayIntervalsAs,
      });
      this.setState({
        displayIntervalsAs,
      });
    }
  }

  setMenuIsOpen(menuIsOpen) {
    const { menuIsOpen: current } = this.state;
    if (menuIsOpen !== current) {
      reactGA.event({
        category: 'Settings',
        action: 'Menu',
        label: menuIsOpen ? 'Open' : 'Close',
      });

      this.setState({
        menuIsOpen,
      });
    }
  }

  setPlaySounds(playSounds, fresh = false, descending = false) {
    const { playSounds: current } = this.state;
    if (current !== playSounds) {
      this.setState({
        playSounds: [playSounds, fresh, descending],
      });
    }
  }

  setDegrees(degrees) {
    const { degrees: current } = this.state;
    if (
      degrees &&
      this.degrees !== current &&
      degrees.every(degree => degree.match(/^[#b]?[vi]+$/i))
    ) {
      const { tonic } = this.state;
      const scaleDegrees = degrees.map(
        degree => new ScaleDegree(tonic, degree)
      );
      this.clearActiveChords();
      this.setState({
        degrees,
        scaleDegrees,
      });
    }
  }

  setIsDegrees(isDegrees) {
    const { isDegrees: current } = this.state;
    if (isDegrees !== current) {
      this.setState({
        isDegrees,
      });
    }
  }

  setTonic(tonic, category = null) {
    const { tonic: current, degrees } = this.state;
    if (tonic && tonic.match(/^[a-g][#b]?$/i) && tonic !== current) {
      const firstLetter = tonic.slice(0, 1).toUpperCase();
      const remainder = tonic.slice(1).toLowerCase();
      const newTonic = `${firstLetter}${remainder}`;
      const scaleDegrees = degrees.map(
        degree => new ScaleDegree(newTonic, degree)
      );
      this.clearActiveChords();
      if (category) {
        reactGA.event({
          category,
          action: 'Set Tonic',
          label: newTonic,
        });
      }
      this.setState({
        tonic: newTonic,
        scaleDegrees,
      });
    }
  }

  setRunTour(runTour) {
    const { runTour: current } = this.state;
    if (current !== runTour) {
      this.setState({
        runTour,
      });
    }
  }

  setActiveChords(...args) {
    if (!args.length) {
      return;
    }
    if (!Array.isArray(args[0])) {
      this.setActiveChords([args[0], args[1], args[2]]);
      return;
    }
    const { activeChords: current } = this.state;

    const [position, chord, sticky] = args.shift();

    const newActiveChords = Object.assign({}, current, {
      [position]: {
        chord,
        sticky: sticky || false,
      },
    });

    if (
      (!newActiveChords.left || !newActiveChords.left.chord) &&
      newActiveChords.right
    ) {
      newActiveChords.left = newActiveChords.right;
      newActiveChords.right = null;
    }
    this.setState(
      {
        activeChords: newActiveChords,
      },
      () => this.setActiveChords(...args)
    );
  }

  getActiveChord(position) {
    const { activeChords } = this.state;
    if (activeChords[position]) {
      return activeChords[position].chord;
    }
    return null;
  }

  toggleDegreesTable(degreesTableIn = null) {
    const { degreesTableIn: current } = this.state;
    let newDegreesTableIn = null;
    newDegreesTableIn = !current;
    if (degreesTableIn !== null && degreesTableIn !== current) {
      newDegreesTableIn = degreesTableIn;
    }

    if (current !== newDegreesTableIn) {
      reactGA.event({
        category: 'Scale',
        action: 'Menu',
        label: newDegreesTableIn ? 'Open' : 'Close',
      });
      this.setState({
        degreesTableIn: newDegreesTableIn,
      });
    }
  }

  clearActiveChords() {
    this.setState({
      activeChords: {},
    });
  }

  isActiveChord(chord, position) {
    const { activeChords } = this.state;
    let isChord = false;
    Object.entries(activeChords).forEach(pair => {
      if (!isChord) {
        const [key, chordSet] = pair;
        if (!position || position === key) {
          isChord =
            chordSet && chordSet.chord && chordSet.chord.id === chord.id;
        }
      }
    });
    return isChord;
  }

  isStickyChord(position) {
    const { activeChords } = this.state;
    return !!(activeChords[position] && activeChords[position].sticky);
  }

  initAnalytics() {
    const { id } = config.ga;
    if (id) {
      reactGA.initialize(id);
      this.hasGA = true;
    }
  }

  trackPageview() {
    if (this.hasGA) {
      reactGA.pageview(window.location.pathname + window.location.search);
    }
  }

  updateStateFromHash() {
    const tonic = hash.get('tonic');
    let degrees = hash.get('degrees');
    if (degrees) {
      degrees = degrees.replace('s', '#').split(',');
    }
    this.setTonic(tonic);
    this.setDegrees(degrees);
  }

  render() {
    const {
      scaleDegrees,
      degrees,
      displayIntervalsAs,
      highlight,
      isDegrees,
      menuIsOpen,
      tonic,
      playSounds,
      activeNote,
      activeNoteSticky,
      runTour,
      degreesTableIn,
      activeChords,
      playRootAfterArpeggio,
    } = this.state;

    const {
      clearActiveChords,
      isActiveChord,
      isStickyChord,
      setActiveChords,
      setTonic,
      setIsDegrees,
      setDegrees,
      setDisplayIntervalsAs,
      setMenuIsOpen,
      setHighlight,
      setPlaySounds,
      setActiveNote,
      getActiveChord,
      setPlayRootAfterArpeggio,
      setRunTour,
      toggleDegreesTable,
    } = this;

    const wrapClass = classNames({
      'tour-in': runTour,
      'box-in': activeChords.left && activeChords.left.chord,
    });

    return (
      <div id="page-wrap" className={wrapClass}>
        <Tour
          clearActiveChords={clearActiveChords}
          degreesTableIn={degreesTableIn}
          degrees={degrees}
          toggleDegreesTable={toggleDegreesTable}
          isStickyChord={isStickyChord}
          runTour={runTour}
          scaleDegrees={scaleDegrees}
          setActiveChords={setActiveChords}
          setDegrees={setDegrees}
          setRunTour={setRunTour}
          setTonic={setTonic}
          setActiveNote={setActiveNote}
        />
        <Settings
          tonic={tonic}
          setTonic={setTonic}
          isDegrees={isDegrees}
          setIsDegrees={setIsDegrees}
          setDegrees={setDegrees}
          degrees={degrees}
          isOpen={menuIsOpen}
          setMenuIsOpen={setMenuIsOpen}
          displayIntervalsAs={displayIntervalsAs}
          setDisplayIntervalsAs={setDisplayIntervalsAs}
          setPlayRootAfterArpeggio={setPlayRootAfterArpeggio}
          playRootAfterArpeggio={playRootAfterArpeggio}
        />
        <div id="chordinatr" className="chordinatr container-fluid">
          <Header
            setMenuIsOpen={setMenuIsOpen}
            menuIsOpen={menuIsOpen}
            tonic={tonic}
            setTonic={setTonic}
            degrees={degrees}
            setDegrees={setDegrees}
            setRunTour={setRunTour}
          />
          <div className="degrees-row row">
            <DegreesContainer
              degrees={degrees}
              displayIntervalsAs={displayIntervalsAs}
              highlight={highlight}
              isDegrees={isDegrees}
              playSounds={playSounds}
              scaleDegrees={scaleDegrees}
              setDegrees={setDegrees}
              setPlaySounds={setPlaySounds}
              getActiveChord={getActiveChord}
              setActiveNote={setActiveNote}
              activeNote={activeNote}
              activeNoteSticky={activeNoteSticky}
              tonic={tonic}
              toggleDegreesTable={toggleDegreesTable}
              degreesTableIn={degreesTableIn}
              playRootAfterArpeggio={playRootAfterArpeggio}
            />
          </div>
          <div className="chords-row row">
            <ChordsContainer
              tonic={tonic}
              degrees={degrees}
              isDegrees={isDegrees}
              setHighlight={setHighlight}
              highlight={highlight}
              displayIntervalsAs={displayIntervalsAs}
              playSounds={playSounds}
              scaleDegrees={scaleDegrees}
              setPlaySounds={setPlaySounds}
              getActiveChord={getActiveChord}
              isActiveChord={isActiveChord}
              setActiveChords={setActiveChords}
              isStickyChord={isStickyChord}
              activeNote={activeNote}
              playRootAfterArpeggio={playRootAfterArpeggio}
            />
          </div>
        </div>
        <Sounds setPlaySounds={setPlaySounds} playSounds={playSounds} />
      </div>
    );
  }
}

export default App;
