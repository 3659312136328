import React, { Component } from 'react';
import classNames from 'classnames';
import ChordInfoBox from 'components/chords/info/box';
import 'intersection-observer';

class ChordInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isIntersecting: false,
    };
  }

  componentDidMount() {
    if (window.IntersectionObserver) {
      const { infoBoxSentinel } = this.props;
      if (infoBoxSentinel.current) {
        const observer = new IntersectionObserver(
          ([{ isIntersecting }]) => {
            const { isIntersecting: current } = this.state;
            if (isIntersecting !== current) {
              this.setState({
                isIntersecting,
              });
            }
          },
          {
            rootMargin: '0px 0px -200px 0px',
            // threshold: 1,
          }
        );
        observer.observe(infoBoxSentinel.current);
      }
    }
  }

  render() {
    const { getActiveChord, isStickyChord, ...props } = this.props;
    let comparisonLeftRight = null;
    let comparisonMiddleLeft = null;
    let comparisonMiddleRight = null;
    let comparisonMiddleLeftRight = null;

    const leftChord = getActiveChord('left');
    const rightChord = getActiveChord('right');
    const middleChord = getActiveChord('middle');

    if (middleChord) {
      comparisonMiddleLeft = middleChord.compareTo(leftChord).pop();
      comparisonMiddleRight = middleChord.compareTo(rightChord).pop();
      comparisonMiddleLeftRight = {
        added: middleChord
          .getNotes()
          .filter(
            note =>
              comparisonMiddleLeft.theirNotes.includes(note) ||
              comparisonMiddleRight.theirNotes.includes(note)
          ),
        removed: middleChord
          .getNotes()
          .filter(
            note =>
              !comparisonMiddleLeft.theirNotes.includes(note) &&
              !comparisonMiddleRight.theirNotes.includes(note)
          ),
        common: comparisonMiddleLeft.common.filter(note =>
          comparisonMiddleRight.common.includes(note)
        ),
      };
    } else if (leftChord && rightChord) {
      comparisonLeftRight = leftChord.compareTo(rightChord).pop();
    }

    const cls = classNames({
      row: true,
      // 'has-sticky': isStickyChord('left') || isStickyChord('right'),
    });

    const { isIntersecting } = this.state;

    const infoboxClassNames = classNames({
      'chord-info-inner': true,
      'has-sticky': isStickyChord('left') || isStickyChord('right'),
      'is-intersecting': !isIntersecting,
    });

    return (
      <div ref={this.infoBoxRef} className={infoboxClassNames}>
        <div className={cls} id="chord-info">
          <div className="col-12 col-sm-4" id="chord-info-left">
            <ChordInfoBox
              comparison={comparisonMiddleLeft || comparisonLeftRight}
              position="left"
              renderActions
              getActiveChord={getActiveChord}
              {...props}
            />
          </div>
          <div className="col-12 col-sm-4" id="chord-info-middle">
            <ChordInfoBox
              comparison={comparisonMiddleLeftRight}
              position="middle"
              getActiveChord={getActiveChord}
              {...props}
            />
          </div>
          <div className="col-12 col-sm-4" id="chord-info-right">
            <ChordInfoBox
              comparison={comparisonMiddleRight || comparisonLeftRight}
              position="right"
              renderActions
              getActiveChord={getActiveChord}
              {...props}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default ChordInfo;
