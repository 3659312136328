import React from 'react';
import classNames from 'classnames';
import propTypesHelper from 'utilities/prop-types-helper';
import DegreesSingle from 'components/degrees/single';

import ChordsSingle from 'components/chords/single';

const ChordsRow = props => {
  const {
    // leftChord,
    chords,
    // rightChord,
    highlight,
    intervals,
    isDegrees,
    scaleDegrees,
    // setLeftChord,
    // setRightChord,
    setHighlight,
    setPlaySounds,
    startingDegree,
    // stickLeftChord,
    getActiveChord,
    setActiveChords,
    isActiveChord,
    isStickyChord,
    activeNote,
  } = props;

  const cls = {};

  cls[`chord-row-${startingDegree.getNote()}`.toLowerCase()] = true;

  if (highlight) {
    const [highlightType, highlightTonic, highlightClass] = highlight;
    if (
      highlightType === 'degree' &&
      highlightTonic === startingDegree.getDegree()
    ) {
      cls[highlightClass] = true;
    }
  }

  const collection = chords.map(chord => (
    <td key={chord.name}>
      <ChordsSingle
        // leftChord={leftChord}
        chord={chord}
        // rightChord={rightChord}
        intervals={intervals}
        isDegrees={isDegrees}
        scaleDegrees={scaleDegrees}
        // setLeftChord={setLeftChord}
        // setRightChord={setRightChord}
        setHighlight={setHighlight}
        setPlaySounds={setPlaySounds}
        startingDegree={startingDegree}
        // stickLeftChord={stickLeftChord}
        getActiveChord={getActiveChord}
        setActiveChords={setActiveChords}
        isActiveChord={isActiveChord}
        isStickyChord={isStickyChord}
        activeNote={activeNote}
      />
    </td>
  ));

  return (
    <tr className={classNames(cls)}>
      <th scope="row">
        <DegreesSingle
          scaleDegree={startingDegree}
          isDegrees={isDegrees}
          isStartingDegree
          label="combo"
        />
      </th>
      {collection}
    </tr>
  );
};

ChordsRow.defaultProps = {
  highlight: '',
};

ChordsRow.propTypes = propTypesHelper({
  chords: true,
  highlight: false,
  intervals: true,
  isDegrees: true,
  scaleDegrees: true,
  setPlaySounds: true,
  startingDegree: true,
});

export default ChordsRow;
