import {
  notes,
  degrees,
  flatDegrees,
  abbrvNameIntervalMap,
  naturalNotes,
} from 'config/notation';

import { isNumber } from 'utilities/general';

function normaliseDegree(degreeToFind) {
  const degree = flatDegrees[degrees[degreeToFind]];
  return degree;
}

function getNatural(note) {
  return note.replace(/(^[a-g])[#b]+$/gi, '$1');
}
function getNaturalDegree(degree) {
  return degree.replace(/^[#b]([vi]+)$/gi, '$1');
}

function flatNote2Sharp(note) {
  if (!note.match(/[a-g]b+$/i)) {
    return note;
  }
  const natural = note.replace(/^([a-g])b+$/i, '$1').toUpperCase();
  let pos = naturalNotes.indexOf(natural) - 1;
  if (pos < 0) {
    pos += naturalNotes.length;
  }
  const naturalToModify = naturalNotes[pos];
  return naturalToModify.match('[EB]')
    ? naturalToModify
    : `${naturalToModify}#`;
}
function sharpNote2Flat(note) {
  if (!note.match(/[a-g]#+$/i)) {
    return note;
  }
  const natural = note.replace(/^([a-g])#+$/i, '$1').toUpperCase();
  let pos = naturalNotes.indexOf(natural) + 1;
  if (pos > naturalNotes.length - 1) {
    pos -= naturalNotes.length;
  }
  const naturalToModify = naturalNotes[pos];
  return naturalToModify.match('[CF]')
    ? naturalToModify
    : `${naturalToModify}b`;
}

function degree2Semitones(degree) {
  const semitones = flatDegrees.indexOf(normaliseDegree(degree));
  if (semitones === undefined) {
    throw new Error('Out of range');
  }
  return semitones;
}

function note2number(note) {
  return notes.indexOf(flatNote2Sharp(note));
}

function buildNaturalScale(tonic) {
  const natural = getNatural(tonic);
  const pos = naturalNotes.indexOf(natural);
  return naturalNotes.slice(pos).concat(naturalNotes.slice(0, pos));
}
function buildChromaticScale(tonic) {
  const pos = notes.indexOf(tonic);
  return notes.slice(pos).concat(notes.slice(0, pos));
}

function getAbbrvNameIntervalFromNumerals(numerals) {
  return abbrvNameIntervalMap[numerals];
}

function getSemitonesFromTonicFromNumerals(numerals) {
  const numeralsMap = [
    'I',
    'bII',
    'II',
    'bIII',
    'III',
    'IV',
    'bV',
    'V',
    'bVI',
    'VI',
    'bVII',
    'VII',
  ];
  return numeralsMap.indexOf(numerals);
}

function addIntervalToNote(note, interval) {
  const noteNumber = note2number(note);
  const semitones = isNumber(interval) ? interval : degree2Semitones(interval);
  let added = noteNumber + semitones;

  if (added > 11) {
    added -= 12;
  } else if (added < 0) {
    added += 12;
  }

  return notes[added];
}

function subtractIntervalFromNote(note, interval) {
  const semitones = isNumber(interval) ? interval : degree2Semitones(interval);

  return addIntervalToNote(note, -semitones);
}

function displayIntervalAs(type, interval) {
  switch (type) {
    case 'abbrv':
      return getAbbrvNameIntervalFromNumerals(interval);
    case 'roman':
      return interval.match(/^b/) ? interval.toLowerCase() : interval;
    case 'semitones':
      return getSemitonesFromTonicFromNumerals(interval);
    case 'numeric': {
      const mapped = getAbbrvNameIntervalFromNumerals(interval);
      if (!mapped) {
        return interval;
      }
      return mapped
        .replace('-', '1')
        .replace('m', 'b')
        .replace('TT', 'b5')
        .replace('P', '')
        .replace('M', '');
    }
    default:
      return interval;
  }
}

function degreeIsNatural(degree) {
  return !!degree.match(/^[vi]+$/i);
}

function degreeIsFlat(degree) {
  return !!degree.match(/^b[vi]+$/i);
}

function degreeIsSharp(degree) {
  return !!degree.match(/^#[vi]+$/i);
}

function noteIsNatural(note) {
  return !!note.match(/^[a-g]+$/i);
}

function noteIsFlat(note) {
  return !!note.match(/^[a-g]b+$/i);
}

function noteIsSharp(note) {
  return !!note.match(/^[a-g]#+$/i);
}

export {
  degreeIsNatural,
  degreeIsFlat,
  degreeIsSharp,
  noteIsNatural,
  noteIsFlat,
  noteIsSharp,
  displayIntervalAs,
  normaliseDegree,
  getNatural,
  getNaturalDegree,
  flatNote2Sharp,
  sharpNote2Flat,
  degree2Semitones,
  note2number,
  buildNaturalScale,
  buildChromaticScale,
  getAbbrvNameIntervalFromNumerals,
  getSemitonesFromTonicFromNumerals,
  addIntervalToNote,
  subtractIntervalFromNote,
};
