import React, { Component } from 'react';
import reactGA from 'react-ga';
import { getNaturalDegree } from 'utilities/notation';
import { naturalDegrees } from 'config/notation';
import FontAwesomeIcon from 'utilities/font-awesome';
import classNames from 'classnames';
import propTypesHelper from 'utilities/prop-types-helper';

class SettingsDegreesEditor extends Component {
  constructor(props) {
    super(props);
    this.selects = [];
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  handleSelectChange() {
    const { setDegrees } = this.props;
    const degrees = this.selects.map(el => {
      return el.current.value;
    });

    degrees.sort(
      (a, b) => naturalDegrees.indexOf(a) - naturalDegrees.indexOf(b)
    );

    setDegrees(degrees);
    reactGA.event({
      category: 'Settings',
      action: 'Degrees',
      label: 'Change degree',
    });
  }

  haveMaxDegrees() {
    const { degrees } = this.props;
    return degrees.length > 11;
  }

  haveMinDegrees() {
    const { degrees } = this.props;
    return degrees.length < 4;
  }

  addOneDegree(pos) {
    const { degrees, setDegrees } = this.props;
    if (!this.haveMaxDegrees()) {
      let split = Math.ceil(pos / 2);
      if (split > degrees.length - 1) {
        split = degrees.length - 1;
      }
      const newDegree = degrees[split];
      const newDegrees = degrees.slice();
      newDegrees.splice(split, 0, newDegree);
      setDegrees(newDegrees);
      reactGA.event({
        category: 'Settings',
        action: 'Degrees',
        label: 'Add degree',
      });
    }
  }

  removeOneDegree(pos) {
    const { degrees, setDegrees } = this.props;
    if (!this.haveMinDegrees()) {
      degrees.splice(pos, 1);
    }
    setDegrees(degrees);
    reactGA.event({
      category: 'Settings',
      action: 'Degrees',
      label: 'Remove degree',
    });
  }

  render() {
    this.selects = [];
    const { degrees, closeModal } = this.props;

    const makeOneSelect = (degree, ix) => {
      const makeOneRemoveButton = i => (
        <button
          disabled={this.haveMinDegrees()}
          type="button"
          className="btn btn-block text-center text-danger btn-link"
          onClick={() => this.removeOneDegree(i)}
        >
          <FontAwesomeIcon icon="times" />
        </button>
      );
      let start = naturalDegrees.indexOf(degrees[ix - 1]);
      if (start < 0) {
        start = 0;
      }
      let end = naturalDegrees.indexOf(degrees[ix + 1]);
      if (end < 0) {
        end = naturalDegrees.length - 1;
      }
      const options = naturalDegrees
        .slice(start, end + 1)
        .map((optDegree, optIx) => {
          const key = `${optDegree}-${optIx}`;
          return <option key={key}>{optDegree}</option>;
        });

      const ref = React.createRef();
      this.selects.push(ref);
      const id = `degree-${ix}`;
      const button = makeOneRemoveButton(ix);
      return (
        <div className="col col-select" key={id}>
          <select
            ref={ref}
            value={getNaturalDegree(degree)}
            className="form-control"
            id={id}
            onChange={this.handleSelectChange}
          >
            {options}
          </select>
          {button}
        </div>
      );
    };

    const makeOneAddButton = i => {
      const disabled = this.haveMaxDegrees();
      const cls = { col: true, 'col-button': true, disabled };

      return (
        <div className={classNames(cls)} key={i}>
          <button
            disabled={disabled}
            type="button"
            onClick={() => this.addOneDegree(i)}
            data-pos={i}
            className="btn text-center text-success btn-block btn-default btn-sm"
          >
            <FontAwesomeIcon icon="plus" />
          </button>
        </div>
      );
    };

    const selects = degrees.map(makeOneSelect);

    let i = 1;
    while (i <= selects.length - 1) {
      selects.splice(i, 0, makeOneAddButton(i));
      i += 2;
    }

    return (
      <form>
        <fieldset className="degrees-edit">
          <legend>Degrees</legend>
          <div className="form-text">
            Add and remove degrees here. You can sharpen or flatten them on the
            main screen.
          </div>
          <div className="form-group">
            <div className="row">{selects}</div>
          </div>
          <button
            type="button"
            className="btn btn-primary float-right"
            onClick={closeModal}
          >
            Done
          </button>
        </fieldset>
      </form>
    );
  }
}

SettingsDegreesEditor.propTypes = propTypesHelper({
  setDegrees: true,
  degrees: true,
  closeModal: true,
});

export default SettingsDegreesEditor;
