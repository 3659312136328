import React from 'react';

const chords = {
  Triads: [
    {
      name: 'Major',
      symbol: '',
      header: 'M',
      lower: false,
      intervals: ['I', 'III', 'V'],
    },
    {
      name: 'Minor',
      symbol: 'm',
      lower: true,
      intervals: ['I', 'bIII', 'V'],
    },
    {
      name: 'Dominant seven',
      symbol: <sup>7</sup>,
      lower: false,
      intervals: ['I', 'III', 'V', 'bVII'],
    },
    {
      name: 'Major seven',
      symbol: <sup>maj7</sup>,
      lower: false,
      intervals: ['I', 'III', 'V', 'VII'],
    },
    {
      name: 'Minor dominant seven',
      symbol: (
        <span>
          m<sup>7</sup>
        </span>
      ),
      lower: true,
      intervals: ['I', 'bIII', 'V', 'bVII'],
    },
    {
      name: 'Minor major seven',
      symbol: (
        <span>
          m<sup>M7</sup>
        </span>
      ),
      lower: true,
      intervals: ['I', 'bIII', 'V', 'VII'],
    },
  ],
  Suspended: [
    {
      name: 'Five / Power Chord',
      symbol: <sup>5</sup>,
      lower: false,
      intervals: ['I', 'V'],
    },
    {
      name: 'Suspended second',
      symbol: <sup>sus2</sup>,
      lower: false,
      intervals: ['I', 'II', 'V'],
    },
    {
      name: 'Suspended fourth',
      symbol: <sup>sus4</sup>,
      lower: false,
      intervals: ['I', 'IV', 'V'],
    },
    {
      name: 'Dominant seven suspended second',
      symbol: (
        <span>
          7<sup>sus2</sup>
        </span>
      ),
      lower: false,
      intervals: ['I', 'II', 'V', 'bVII'],
    },
    {
      name: 'Dominant seven suspended fourth',
      symbol: (
        <span>
          7<sup>sus4</sup>
        </span>
      ),
      lower: false,
      intervals: ['I', 'IV', 'V', 'bVII'],
    },
  ],
  Diminished: [
    {
      name: 'Diminished',
      symbol: <sup>&#176;</sup>,
      lower: true,
      intervals: ['I', 'bIII', 'bV'],
    },
    {
      name: 'Flat 5',
      symbol: <sup>&#9837;5</sup>,
      lower: false,
      intervals: ['I', 'III', 'bV'],
    },
    {
      name: 'Dominant seven flat 5',
      symbol: <sup>7&#9837;5</sup>,
      lower: false,
      intervals: ['I', 'III', 'bV', 'bVII'],
    },
    {
      name: 'Half diminished seven',
      symbol: (
        <span>
          m<sup>7&#9837;5</sup>
        </span>
      ),
      lower: true,
      intervals: ['I', 'bIII', 'bV', 'bVII'],
    },
    {
      name: 'Fully diminished seven',
      symbol: (
        <span>
          <sup>&#176;7</sup>
        </span>
      ),
      lower: false,
      intervals: ['I', 'bIII', 'bV', 'VI'],
      intervalsLabel: ['1', 'b3', 'b5', 'bb7'],
    },
    {
      name: 'Diminished major seven',
      symbol: <sup>&#176;M7</sup>,
      lower: false,
      intervals: ['I', 'bIII', 'bV', 'VII'],
    },
    {
      name: 'Diminished nine',
      symbol: <sup>&#176;9</sup>,
      lower: false,
      intervals: ['I', 'III', 'bV', 'VII', 'II'],
    },
  ],
  Augmented: [
    {
      name: 'Augmented',
      symbol: <sup>&#43;</sup>,
      lower: false,
      intervals: ['I', 'III', 'bVI'],
    },
    {
      name: 'Augmented minor seven',
      symbol: <sup>&#43;7</sup>,
      lower: false,
      intervals: ['I', 'III', 'bVI', 'bVII'],
    },
    {
      name: 'Augmented major seven',
      symbol: <sup>&#43;M7</sup>,
      lower: false,
      intervals: ['I', 'III', 'bVI', 'VII'],
    },
    {
      name: 'Augmented nine',
      symbol: <sup>&#43;9</sup>,
      lower: false,
      intervals: ['I', 'III', 'bVI', 'VII', 'II'],
    },
  ],
  Sixes: [
    {
      name: 'Six',
      symbol: <sup>6</sup>,
      lower: false,
      intervals: ['I', 'III', 'V', 'VI'],
    },
    {
      name: 'Minor six',
      symbol: (
        <span>
          m<sup>6</sup>
        </span>
      ),
      lower: true,
      intervals: ['I', 'bIII', 'V', 'VI'],
    },
    {
      name: 'Six / nine',
      symbol: (
        <span>
          <sup>69</sup>
        </span>
      ),
      lower: true,
      intervals: ['I', 'III', 'V', 'VI', 'II'],
    },
  ],
  Nines: [
    {
      name: 'Add nine',
      symbol: <sup>add9</sup>,
      lower: false,
      intervals: ['I', 'III', 'V', 'II'],
    },
    {
      name: 'Minor add nine',
      symbol: (
        <span>
          m<sup>add9</sup>
        </span>
      ),
      lower: false,
      intervals: ['I', 'bIII', 'V', 'II'],
    },
    {
      name: 'Dominant nine',
      symbol: <sup>9</sup>,
      lower: false,
      intervals: ['I', 'III', 'V', 'bVII', 'II'],
    },
    {
      name: 'Minor Dominant nine',
      symbol: (
        <span>
          m<sup>9</sup>
        </span>
      ),
      lower: false,
      intervals: ['I', 'bIII', 'V', 'bVII', 'II'],
    },
    {
      name: 'Dominant nine suspended fourth',
      symbol: <sup>9sus4</sup>,
      lower: false,
      intervals: ['I', 'IV', 'V', 'bVII', 'II'],
    },
    {
      name: 'Major nine',
      symbol: <sup>maj9</sup>,
      lower: false,
      intervals: ['I', 'III', 'V', 'VII', 'II'],
    },
    {
      name: 'Dominant minor nine',
      symbol: <sup>7&#9837;9</sup>,
      lower: false,
      intervals: ['I', 'III', 'V', 'bVII', 'bII'],
    },
  ],
  Elevens: [
    {
      name: 'Add eleven',
      symbol: <sup>add11</sup>,
      lower: false,
      intervals: ['I', 'III', 'V', 'IV'],
    },
    {
      name: 'Minor add eleven',
      symbol: (
        <span>
          m<sup>add11</sup>
        </span>
      ),
      lower: false,
      intervals: ['I', 'bIII', 'V', 'IV'],
    },
    {
      name: 'Dominant eleven',
      symbol: <sup>11</sup>,
      lower: false,
      intervals: ['I', 'III', 'V', 'bVII', 'II', 'IV'],
    },
    {
      name: 'Major eleven',
      symbol: <sup>maj11</sup>,
      lower: false,
      intervals: ['I', 'III', 'V', 'VII', 'II', 'IV'],
    },
    {
      name: 'Dominant sharp eleven',
      symbol: <sup>#11</sup>,
      lower: false,
      intervals: ['I', 'III', 'V', 'bVII', 'II', 'bV'],
    },
    {
      name: 'Major sharp eleven',
      symbol: <sup>maj#11</sup>,
      lower: false,
      intervals: ['I', 'III', 'V', 'VII', 'II', 'bV'],
    },
  ],
  Thirteens: [
    {
      name: 'Dominant thirteen',
      symbol: <sup>13</sup>,
      lower: false,
      intervals: ['I', 'III', 'V', 'bVII', 'II', 'IV', 'VI'],
    },
    {
      name: 'Major thirteen',
      symbol: <sup>maj13</sup>,
      lower: false,
      intervals: ['I', 'III', 'V', 'VII', 'II', 'IV', 'VI'],
    },
  ],
};

export default {
  getSets: () => chords,
  getAll: () => Object.values.reduce((acc, current) => acc.concat(current), []),
};
