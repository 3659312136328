import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faTimes,
  faInfoCircle,
  faCog,
  faEdit,
  faPlus,
  faVolumeUp,
  faCaretUp,
  faCaretDown,
  faQuestion,
} from '@fortawesome/free-solid-svg-icons';

library.add([
  faTimes,
  faInfoCircle,
  faCog,
  faEdit,
  faPlus,
  faVolumeUp,
  faCaretDown,
  faCaretUp,
  faQuestion,
]);

export default FontAwesomeIcon;
