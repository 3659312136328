import React, { Component } from 'react';
import reactGA from 'react-ga';
import PropTypes from 'prop-types';
import { getNaturalDegree } from 'utilities/notation';
import classNames from 'classnames';
import propTypesHelper from 'utilities/prop-types-helper';

import DegreesControls from 'components/degrees/controls';
import DegreesSingle from 'components/degrees/single';
import ScaleDegree from 'utilities/degree';

class DegreeRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eliminatedIntervals: [],
    };
    this.alterDegree = this.alterDegree.bind(this);
    this.toggleEliminateInterval = this.toggleEliminateInterval.bind(this);
  }

  alterDegree(modifier = '') {
    const { setDegrees, degrees, position } = this.props;
    const degree = getNaturalDegree(degrees[position]);

    const modified = `${modifier}${degree}`;
    if (degrees[position] !== modified) {
      const newDegrees = degrees.slice();

      reactGA.event({
        category: 'Scale',
        action: 'alter',
        label: modified,
      });

      newDegrees[position] = modified;
      setDegrees(newDegrees);
    }
  }

  toggleEliminateInterval(degree) {
    const { eliminatedIntervals } = this.state;
    const updated = eliminatedIntervals.filter(
      eliminatedInterval => eliminatedInterval !== degree
    );
    if (eliminatedIntervals.length === updated.length) {
      updated.push(degree);
    }
    this.setState({
      eliminatedIntervals: updated,
    });
  }

  render() {
    const {
      degrees,
      displayIntervalsAs,
      startingDegree,
      tonic,
      setDegrees,
      setHighlight,
      highlight,
      setPlaySounds,
      getActiveChord,
    } = this.props;

    const { eliminatedIntervals } = this.state;

    const cls = {};
    cls[`degree-row-${startingDegree.getNote()}`.toLowerCase()] = true;

    if (highlight) {
      const [highlightType, highlightTonic, highlightClass] = highlight;
      if (
        highlightType === 'chord' &&
        highlightTonic === startingDegree.getDegree()
      ) {
        cls[highlightClass] = true;
      }
    }

    const scaleDegreeElements = degrees.map((degree, ix) => {
      const scaleDegree = new ScaleDegree(tonic, degree);
      const isStartingDegree =
        startingDegree.getDegree() === scaleDegree.getDegree();

      return (
        <td key={ix}>
          <div className="degrees-cell-inner">
            <DegreesSingle
              displayIntervalsAs={displayIntervalsAs}
              eliminatedIntervals={eliminatedIntervals}
              hasSound
              isStartingDegree={isStartingDegree}
              label="interval"
              scaleDegree={scaleDegree}
              setHighlight={setHighlight}
              startingDegree={startingDegree}
              toggleEliminateInterval={this.toggleEliminateInterval}
              setPlaySounds={setPlaySounds}
              getActiveChord={getActiveChord}
            />
          </div>
        </td>
      );
    });

    return (
      <tr className={classNames(cls)}>
        <DegreesControls
          alterDegree={this.alterDegree}
          key="controls"
          scaleDegree={startingDegree}
          setDegrees={setDegrees}
        />
        {scaleDegreeElements}
      </tr>
    );
  }
}

DegreeRow.defaultProps = {
  highlight: '',
};

DegreeRow.propTypes = propTypesHelper(
  {
    degrees: true,
    displayIntervalsAs: true,
    startingDegree: true,
    tonic: true,
    setDegrees: true,
    setPlaySounds: true,
    highlight: false,
  },
  {
    position: PropTypes.number.isRequired,
  }
);

export default DegreeRow;
