import loglevel from 'loglevel';

const { NODE_ENV } = process.env;

loglevel.setLevel(loglevel.levels.SILENT);
if (NODE_ENV === 'development') {
  loglevel.setLevel(loglevel.levels.TRACE);
}

export default loglevel;
