import React from 'react';
import SettingsRadio from 'components/settings/radio';

import PropTypes from 'prop-types';
import propTypesHelper from 'utilities/prop-types-helper';

const SettingsPlayRootAfterArpeggio = props => {
  const { setPlayRootAfterArpeggio, playRootAfterArpeggio } = props;
  return (
    <fieldset>
      <div>
        <strong>Play root note at end of argpeggio / scale:</strong>
      </div>
      <SettingsRadio
        checked={!!playRootAfterArpeggio}
        id="play-root-after-arpeggio-true"
        label="Yes"
        setter={setPlayRootAfterArpeggio}
        value
      />
      <SettingsRadio
        checked={!playRootAfterArpeggio}
        id="play-root-after-arpeggio-false"
        label="No"
        setter={setPlayRootAfterArpeggio}
        value={false}
      />
    </fieldset>
  );
};

SettingsPlayRootAfterArpeggio.propTypes = propTypesHelper(
  {},
  {
    playRootAfterArpeggio: PropTypes.bool.isRequired,
    setPlayRootAfterArpeggio: PropTypes.func.isRequired,
  }
);

export default SettingsPlayRootAfterArpeggio;
